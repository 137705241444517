import React, { useState, useEffect } from "react";
import "./scientific_studie.scss";
import FavoriteIcon from "@mui/icons-material/Favorite";
import IconButton from "@mui/material/IconButton";

import { Formik, Field, Form } from "formik";
import axios from 'axios';

const Scientific = ({
  abstract,
  authors,
  title,
  readMore,
  readLess,
  full_url,
  url_img,
  favoris
}) => {
  const [show, setShow] = useState(false);
  const [liked, setLiked] = useState(false);


  const handelLiked = () => {
    setLiked(!liked);
  };
  let auth=[];
  auth=JSON.parse(sessionStorage.getItem('userData'))



 const hostName="/Back-end/addFavoris";
 //const hostName="http://localhost/Back-end/addFavoris"
  const handleSubmit = (dataFavoris) => {
  
       axios.post(hostName,dataFavoris)
       
      .then((response)=>{
      // console.log(response)
      window.location.reload(false)
      }).catch(error=>{
        console.log(error)
      })
    
  };
  
  return (
    <>
      <Formik
        initialValues={{
          title: title,
          abstract: abstract,
          authors: authors,
          full_url:full_url,
          favoris:"study",
          url_img:url_img,
          status:true,
          user_id:auth["user_id"],
        }}
        onSubmit={(values) => {
          handleSubmit(values)
          //console.log(values);
        }}
      >
        {({ values }) => (
          <Form>
            <div className="msgExist"></div>
            <div  className='card borderCard' style={{width:"auto"}}>
            <div className="card-header headerFlex" style={{height:"8rem"}}>
              <img src={url_img} alt=""/>
              <span className="titleHeader"> {title}</span>
            </div>
           
             
            <div className="card-body">
              <div style={{height:"5rem", marginBottom:"5px"}}>
              <h6 className="card-subtitle text-muted">{authors}</h6>
              </div>
              {abstract != "" ? (
                <p>{show ? abstract : abstract.substr(0, 100) + "....."}</p>
              ) : (
                <p></p>
              )}
              <span className="buttonRead color" onClick={() => setShow(!show)}>
                <span className="hyphen"></span>
                <span className="buttonRead_text">
                  {" "}
                  {!show ? readMore : readLess}
                </span>
              </span>
            </div>

            <div className="card-footer flex">
              <a
                className="center"
                href={`https://mad-up.com/api/uploads/mad-up/originals/${full_url}?download`}
                target="_blank"
                download="Your File.pdf"
              >
                <IconButton aria-label="download">
                  <i className="fa-solid fa-download"></i>
                </IconButton>
              </a>
             

              {favoris  ?
                    
                  <IconButton aria-label="add to favorites" className="center" type="submit" onClick={handelLiked}>
                  <FavoriteIcon color="error" />
                </IconButton>
               :
                     <IconButton aria-label="add to favorites" onClick={handelLiked} type="submit">
                    <FavoriteIcon />
                  </IconButton>
                }
            </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Scientific;
