import React, {useState, useEffect} from "react";
import "./LogoMadUPAcademy.scss";
import {Link, useParams} from "react-router-dom";
import * as dataJSON from "../../Data/NavBarAccount.json";

const LogoAcademy = (props) => {
    let {lang} = useParams();

    const [data, setData] = useState(dataJSON.default[lang]);

    useEffect(() => {
        setData(dataJSON.default[lang]);
    }, [lang]);
    return (
        <div className={`logo-wrapper`}>
            <Link to={`/${lang}/HomeAcademy`} className="logo" style={{backgroundColor:"transparent"}}>
                <img src="https://mad-up.com/api/uploads/mad-up/originals/traLogoMADAcademy.png" alt="Logo"/>
                
            </Link>
           
            
        </div>
    );
};
export default LogoAcademy;
