import React from 'react'
import { BiMailSend } from 'react-icons/bi';
import "./ConfirmEmail.scss"
export const ConfirmEmail = ({h1, text1, text2,text3,text4}) => {
  return (
    <div >
        <div className='confirm'> 
            <BiMailSend/>
            <h1>{h1}</h1>
            <div>
                <p>{text1}</p>
                <p>{text2}</p>
                <p><strong>{text3}</strong> {text4} </p> 

            </div>
            
        </div>

    </div>
  )
}

export default ConfirmEmail
