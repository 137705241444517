import React,{useState, useEffect} from 'react';
import './GetInterest.scss';
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import UpdateInterest from "../UpdateInterests/UpdateInterest"
import BigTitle from "../BigTitle";
import axios from "axios";
export const GetInterest = (props) => {
 
  const[interset, setInterset]=useState([]);
  const [isChecked, setIsChecked] = useState(true);

  const handleChangeInput = event => {
    setIsChecked(event.target.checked);
    alert("cliqué")
  }

  let auth=[];
  auth=JSON.parse(sessionStorage.getItem('userData'))
  const user_id=auth.user_id;

   const initialValues = {
    epaule:"",
    genou:"",
    coude:"",
    hanche:"",
    cheville_pied:"",
    poignet_main:"",
    sport:"",
    sante:"",
    reeducation:"",
    user_id:auth["user_id"]
  };

const hostName="/Back-end/addInterest";
//const hostName="http://localhost/Back-end/addInterest"
const handleSubmit = (dataInterest) => {
  console.log(dataInterest);
     axios.post(hostName, dataInterest)
     
    .then((response)=>{
      console.log(response.data)
      window.location.reload(); 
     
     
      // if (response.data.status===true){
      //     setStatus(true);
         
         
      // }
      // else{
          
      //     document.querySelector('.error').innerHTML=response.data.message
      // }
      
    }).catch(error=>{
      console.log(error)
    })
  
};
const hostName2="/Back-end/getInterest"
//const hostName2="http://localhost/Back-end/getInterest"
useEffect(() => {
  axios
    .get(
      `${hostName2}/${user_id}`)
    .then((reponse) => {
      setInterset(reponse.data);
     console.log(reponse.data)
     sessionStorage.setItem('interest',JSON.stringify(reponse.data))
    });
}, []);
return (
    <>
      <section className="interset">

   <BigTitle text="" title={props.titleSpan} small="" direct="left" />
  
  {(interset.length===0) ? 
      <Formik
        initialValues={initialValues}
        onSubmit={(interData) => {
          handleSubmit(interData);
          //console.log(interData)
          
        }}
      >
        {({ handleChange, handleBlur, values, setFieldValue, handleReset }) => (
          <Form>
          
            <p>{props.text1}</p>
            <div className='intersetDiv'>
                <label  
                >
              <Field type="checkbox" name="sport"  value={props.span1}
            
              />
              <span>{props.span1}</span>
            </label>

          
              
            <label  
                >
              <Field type="checkbox" name="sante"  value={props.span2}
            
              />
              <span>{props.span2}</span>
            </label>
            <label  
                >
              <Field type="checkbox" name="reeducation"  value={props.span3}
             
              />
              <span>{props.span3}</span>
            </label>
            </div>
            <p>{props.text2}</p>
            <div className='intersetDiv'>
            <label
                >
              <Field type="checkbox" name="epaule"  value={props.span4}
              />
              <span>{props.span4}</span>
            </label>
            <label  
                >
              <Field type="checkbox" name="genou"  value={props.span5}
            
              />
              <span>{props.span5}</span>
            </label>
            <label  
                >
              <Field type="checkbox" name="coude"  value={props.span6}
             
              />
              <span>{props.span6}</span>
            </label>
            </div>
            <div className='intersetDiv'>
            <label 
                >
              <Field type="checkbox" name="hanche"  value={props.span7}
            
              />
              <span>{props.span7}</span>
            </label>
            <label 
                >
              <Field type="checkbox" name="cheville_pied"  value={props.span8}
            
              />
              <span>{props.span8}</span>
            </label>
            <label
                >
              <Field type="checkbox" name="poignet_main"  value={props.span9}
             
              />
              <span>{props.span9}</span>
            </label>
            </div>
           
            <button type="submit" className="buttonSubmit btn">
              {props.ButtonText}
            </button>
               
    
          </Form>
        )}
      </Formik>: 

      interset.map((interset)=>{
        return (
          <div key={interset.id} className='getInterset'>
              <p>{props.text1}</p>
              <div>
              {interset.sport === "\"\"" ? <span>{props.span1}</span> : <span className='active'>{props.span1}</span>}



            {interset.sante === "\"\"" ? <span>{props.span2}</span > : <span className='active'>{props.span2}</span>  }
            {interset.reeducation === "\"\"" ? <span>{props.span3}</span > : <span className='active'>{props.span3}</span>}
            </div>
            <p>{props.text2}</p>
            <div> 
        <div> 
        {interset.epaule === "\"\"" ? <span>{props.span4}</span> : <span className='active'>{props.span4}</span>}
        {interset.genou === "\"\"" ? <span>{props.span5}</span> : <span className='active'>{props.span5}</span>}
        {interset.coude === "\"\"" ? <span>{props.span6}</span> : <span className='active'>{props.span6}</span>}
        </div>
        <div>
        {interset.hanche === "\"\"" ? <span>{props.span7}</span> : <span className='active'>{props.span7}</span>}
        {interset.cheville_pied === "\"\"" ? <span>{props.span8}</span> : <span className='active'>{props.span8}</span>}
        {interset.poignet_main === "\"\"" ? <span>{props.span9}</span> : <span className='active'>{props.span9}</span>}   
        </div>
        </div>
        <UpdateInterest ButtonText2={props.ButtonText2}
          span1={props.span1}
          span2={props.span2}
          span3={props.span3}
          span4={props.span4}
          span5={props.span5}
          span6={props.span6}
          span7={props.span7}
          span8={props.span8}
          span9={props.span9}
          text1={props.text1}
          text2={props.text2}
          ButtonText={props.ButtonText}
        />

{/* <Formik
        initialValues={initialValues}
        onSubmit={(interData) => {
         console.log(interData);
          
        }}
      >
        {({ handleChange, handleBlur, values, setFieldValue, handleReset }) => (
          <Form>
          
            <p>{props.text1}</p>
            <div className='intersetDiv'>
            {interset.sport === "\"\"" ? <label  
                >
              <Field type="checkbox" name="sport"  value={props.span1}
            
              />
              <span>{props.span1}</span>
            </label> : 
            <label>
            <Field type="checkbox" name="sport"  value={props.span1} 
               
                 checked
            
            />
            <span>{props.span1}</span>
          </label>
            }


               {interset.sante === "\"\"" ? 
              
            <label  
                >
              <Field type="checkbox" name="sante"  value={props.span2}
            
              />
              <span>{props.span2}</span>
            </label>
            :
            <label>
          <Field type="checkbox" name="sante"  value={props.span2}
            checked
                
          />
          <span>{props.span2}</span>
        </label>
       
      }
      {interset.reeducation === "\"\"" ?
        <label  
            >

          <Field type="checkbox" name="reeducation"  value={props.span3}
         
          />
          <span>{props.span3}</span>
        </label>:
      
            <label  
                >
              <Field type="checkbox" name="reeducation"  value={props.span3}
                 
                   
                checked
              />
              <span>{props.span3}</span>
            </label> 
      }
            </div>
            <p>{props.text2}</p>
            <div className='intersetDiv'>
            {interset.epaule === "\"\"" ?
            <label
                >
              <Field type="checkbox" name="epaule"  value={props.span4}
              />
              <span>{props.span4}</span>
            </label>:
            <label
            >
          <Field type="checkbox" name="epaule"  value={props.span4}
             
                onChange={handleChange}
                checked={isChecked}
          />
          <span>{props.span4}</span>
        </label>}
        {interset.genou === "\"\"" ? 
            <label  
                >
              <Field type="checkbox" name="genou"  value={props.span5}
            
              />
              <span>{props.span5}</span>
            </label>:
              <label  
              >
            <Field type="checkbox" name="genou"  value={props.span5}
             onChange={handleChange}
             
                checked={isChecked}
            />
            <span>{props.span5}</span>
          </label>}
          {interset.coude === "\"\"" ?
            <label  
                >
              <Field type="checkbox" name="coude"  value={props.span6}
             
              />
              <span>{props.span6}</span>
            </label> :
             <label  
             >
           <Field type="checkbox" name="coude"  value={props.span6}
            onChange={handleChange}
                checked={isChecked}
           />
           <span>{props.span6}</span>
         </label>}
            </div>
            <div className='intersetDiv'>
            {interset.hanche === "\"\"" ?
            <label 
                >
              <Field type="checkbox" name="hanche"  value={props.span7}
            
              />
              <span>{props.span7}</span>
            </label>:
             <label 
             >
           <Field type="checkbox" name="hanche"  value={props.span7}
              onChange={handleChange}
                checked={isChecked}
           />
           <span>{props.span7}</span>
         </label>}
         {interset.cheville_pied === "\"\"" ?
            <label 
                >
              <Field type="checkbox" name="cheville_pied"  value={props.span8}
            
              />
              <span>{props.span8}</span>
            </label> :
               <label 
               >
             <Field type="checkbox" name="cheville_pied"  value={props.span8}
             onChange={handleChange}
                checked={isChecked}
             />
             <span>{props.span8}</span>
           </label>
      }
      {interset.poignet_main === "\"\"" ?
            <label
                >
              <Field type="checkbox" name="poignet_main"  value={props.span9}
             
              />
              <span>{props.span9}</span>
            </label>:
             <label
             >
           <Field type="checkbox" name="poignet_main"  value={props.span9}
            onChange={handleChange}
                checked={isChecked}
           />
           <span>{props.span9}</span>
         </label>}
            </div>
           
            <button type="submit" className="buttonSubmit">
              {props.ButtonText2}
            </button>
               
    
          </Form>
        )}
      </Formik>
        */}
          </div>
        )
      })
      
 } 

      </section>
    </>
    
  )
};

export default GetInterest;
