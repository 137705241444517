import React, { useState, useEffect, useRef } from "react";
import { Navigate, useParams } from "react-router-dom";
import Meta from "../../Components/Meta";
import * as dataJson from "../../Data/Protocol.json";
import axios from "axios";
import Protocol from "../../Components/Protocol/Protocol";
import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount";
import SideBar from "../../Components/SideBar/SideBar";
import Breadcrumb from "../../Components/Breadcrumb";
import Pagination from "../../Components/Pagination/Pagination";
import Footer from "../../Components/Footer";
const Protocols = (props) => {
  const { lang } = useParams();
  const [data, setData] = useState(dataJson.default[lang]);
  const [protocols, setProtocols] = useState([]);
  const [category, setCategory] = useState([]);
  const [filterCategory, setFilterCategory] = useState(null);
  const [member, setMember] = useState(null);
  const [filterJoint, setFilterJoint] = useState(null);
  
  const [study, setStudy]=useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(12);

  const prevCategoryRef = useRef();
  const prevJointRef = useRef();

  const [meta, setMeta] = useState({
    title: dataJson.default[lang].meta.title,
    description: dataJson.default[lang].meta.description,
    canonical: dataJson.default[lang].meta.canonical,
    image: dataJson.default[lang].meta.image,
    meta: {
      charset: "utf-8",
      name: {
        keywords: dataJson.default[lang].meta.keywords,
      },
    },
  });

  useEffect(() => {
    setData(dataJson.default[lang]);
    setMeta({
      title: dataJson.default[lang].meta.title,
      description: dataJson.default[lang].meta.description,
      canonical: dataJson.default[lang].meta.canonical,
      keywords: dataJson.default[lang].meta.keywords,
      image: dataJson.default[lang].meta.image,
    });
  }, [lang]);
  const loadData = () => {
    const category = filterCategory ? `filter[pathologie]=${filterCategory}` : "";
    const joint = filterJoint ? `filter[phase]=${filterJoint}` : "";
    //const url=`https://mad-up.com/api/mad-up/items/protocols?fields=id,translations.*&lang=${lang},file.*,member.id,member.slug,category.id,category.slug&${category}&${joint}`
    const url = `https://mad-up.com/api/mad-up/items/protocols?fields=id,image.*,pathologie.*,phase.*,translations.*,translations.file.*,translations.language&lang=${lang},phase.id,phase.slug,pathologie.id,pathologie.slug&${category}&${joint}`;
   // console.log(url);
    axios.get(url).then((reponse) => {
      setProtocols(reponse.data.data);
      //console.log(reponse.data.data);
    });
  };
  useEffect(() => {
    prevCategoryRef.current = filterCategory;
    prevJointRef.current = filterJoint;
    loadData();
    //console.log(filterCategory)
  }, [filterCategory, filterJoint, lang]);

  useEffect(() => {
    axios
      .get(
        `
        https://mad-up.com/api/mad-up/items/pathologie_filter?fields=id,slug,translations.name,translations.language&lang=${lang}`
      )
      .then((reponse) => {
        setCategory(reponse.data.data);
       // console.log(reponse.data.data)
      });
  }, [lang]);
  useEffect(() => {
    axios
      .get(
        `
        https://mad-up.com/api/mad-up/items/phase_filter?fields=id,slug,translations.name,translations.language&lang=${lang}`
      )
      .then((reponse) => {
        setMember(reponse.data.data);
        // console.log(reponse.data.data)
      });
  }, [lang]);
  //console.log(member)

    
  

  const selectCategory = (idCategory) => {
    // console.log(`demande de la catégorie ${idCategory}`)
    setFilterCategory(idCategory);

    //loadData()
  };
  //console.log(filterCategory)

  const selectJoint = (idJoint) => {
    // console.log(`demande de l'articulation ${idJoint}`)
    setFilterJoint(idJoint);
  };
  const hostName1="/Back-end/getFavoris";
  // const hostName1="http://localhost/Back-end/getFavoris"
  useEffect(() => {
    axios
    .get(
      `${hostName1}/${auth["user_id"]}`
    )
    .then((reponse) => {
      setStudy(reponse.data)
      
    });
  }, []);
  const auth = JSON.parse(sessionStorage.getItem("userData"));





 
  if (auth === null) {
    return <Navigate to={`/${lang}/connexion`} />;
  }
   //connaitre la page courante
   const indexOfLastArticle = currentPage * articlesPerPage;
   const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
   const currentArticles = protocols.slice(
     indexOfFirstArticle,
     indexOfLastArticle
   );
 
   // changement de page
   const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <>
      <Meta meta={meta} />
      <HeaderAccount />
      <Breadcrumb title={data.breadcrumb} />    
        <SideBar>
        <div>
          <div className="filter">

          {/* <label htmlFor="categorie">
          {data.textCategory}
            <select
              className="select"
              name="category"
              id="category"
              onChange={(event) => selectCategory(event.target.value)}
            >
              <option value="" selected={filterCategory === null && "DefaultSelected"}>
                {data.textOption}
              </option>
              {category &&
                category.map((category, id) => {
                  return (
                    <option
                      key={id}
                      value={category.id}
                      selected={filterCategory === category.id && "DefaultSelected"}
                    >
                      {category.translations[0].name}
                    </option>
                  );
                })}
            </select>
                </label> */}
                {/* <label htmlFor="articulation">
              {data.textArticulation}
            <select
              className="select"
              name="articulation"
              id="articulation"
              onChange={(event) => selectJoint(event.target.value)}
            >
              <option value="" selected={filterJoint === null && "DefaultSelected"}>
                {data.textOption}
              </option>
              {member &&
                member.map((member, id) => {
                  return (
                    <option
                      key={id}
                      value={member.id}
                      selected={filterJoint === member.id && "DefaultSelected"}
                    >
                      {member.translations[0].name}
                    </option>
                  );
                })}
            </select>
            </label> */}
            {/* {filterCategory && (
              <button
                type="button"
                className="closeButton btn"
                onClick={handleRestFilterCategory}
              >
                {categoryName} &nbsp;
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  className="bi bi-x-circle-fill"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.146-3.146a.5.5 0 0 0-.708-.708L8 7.293 4.854 4.146a.5.5 0 1 0-.708.708L7.293 8l-3.147 3.146a.5.5 0 0 0 .708.708L8 8.707l3.146 3.147a.5.5 0 0 0 .708-.708L8.707 8l3.147-3.146z"
                  />
                </svg>
              </button>
            )} */}
            {/* {filterJoint && (
              <button
                type="button"
                className="closeButton btn"
                onClick={handleRestFilterJoint}
              >
                {jointName} &nbsp;
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  className="bi bi-x-circle-fill"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.146-3.146a.5.5 0 0 0-.708-.708L8 7.293 4.854 4.146a.5.5 0 1 0-.708.708L7.293 8l-3.147 3.146a.5.5 0 0 0 .708.708L8 8.707l3.146 3.147a.5.5 0 0 0 .708-.708L8.707 8l3.147-3.146z"
                  />
                </svg>
              </button>
            )} */}
          </div>
          <div className="gridCard">
            {currentArticles &&
              currentArticles.map((prt) => {
                return (
                  
                    <Protocol
                     key={prt.id}
                      title={prt.translations[0].title}
                      abstract={prt.translations[0].abstract}
                      idJoint={prt.phase.id}
                      idCategory={prt.pathologie.id}
                      url_img={prt.image.data.full_url}
                      filterCategory={selectCategory}
                      filterJoint={selectJoint}
                      full_url={prt.translations[0].file.filename_disk}
                      textdownload={data.textdownload}
                      readMore={data.readMore}
                      readLess={data.readLess}
                      favoris={study.find(s=>s.title===prt.translations[0].title)}
                    />
                  
                );
              })}
          </div>
          <Pagination
            articlesPerPage={articlesPerPage}
            totalArticles={protocols.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      </SideBar>
      <Footer/>      
    </>
  );
};

export default Protocols;
