import React from "react";
import { Link } from "react-router-dom";
import "./Pagination.scss";
import { MDBPagination, MDBPaginationItem, MDBPaginationLink } from 'mdb-react-ui-kit';

const Pagination = ({ currentPage, articlesPerPage, totalArticles, paginate }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalArticles / articlesPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
   

    <nav aria-label='Page navigation' className="pagination d-flex justify-content-center mt-4 mb-2">
    <MDBPagination  className='mb-0'>
    {currentPage-1 ==0 ?
      <MDBPaginationItem disabled>
      <MDBPaginationLink>
            <span aria-hidden='true'>«</span>
       </MDBPaginationLink >
      </MDBPaginationItem> :
       <MDBPaginationItem>
       <MDBPaginationLink   to={currentPage-1} onClick={() => paginate(currentPage-1)} aria-label="Previous" >
             <span aria-hidden='true'>«</span>
        </MDBPaginationLink >
       </MDBPaginationItem>
    }
          
  {pageNumbers.map((number) => (
      
      <MDBPaginationItem className={`page-item ${currentPage === number ? "activePage" : ""}`} key={number} aria-current='page'>
         <MDBPaginationLink 
              onClick={() => paginate(number)}
              to={number}
              
            >
             {number} <span className='visually-hidden'>(current)</span>
            </MDBPaginationLink >
      </MDBPaginationItem>
      ))}
      {currentPage+1 !=pageNumbers.length ?
      <MDBPaginationItem disabled>
      <MDBPaginationLink   className="page-item" >
        <span aria-hidden='true'>»</span>
      </MDBPaginationLink >
  </MDBPaginationItem>:
      <MDBPaginationItem>
          <MDBPaginationLink   to={currentPage+1} onClick={() => paginate(currentPage+1)} aria-label="Next">
            <span aria-hidden='true'>»</span>
          </MDBPaginationLink >
      </MDBPaginationItem>
    }
    </MDBPagination>
  </nav>






  );
};

export default Pagination;