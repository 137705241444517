import React from "react";
import Language from "../Languages";
import Burger from "./BurgerAccount";
import SearchIcon from '@mui/icons-material/Search';
// import "./Navbar.scss";
import LogoMadUPAcademy from "../LogoMadUPAcademy/LogoMadUPAcademy";
import NavBarItem from "./Navbar";
import SearchBar from "../SearchBar/SearchBar"
import "./responsive.scss"
const HeaderNavbar = () => {
    return (
  <div >
    
    <nav className="container-fluid responsive">
    <div className="right">
      <LogoMadUPAcademy/>
     
      <div>
          <NavBarItem/>
          <Burger />
      </div>
     
      </div>
      <Language /> 
        {/* <div id="navBar-form">
          <input type="checkbox" id="box-2" />
          <form className="search-form">
            <SearchBar
            id="search-bar"
             />
             {/* <button id="search-btn" type="submit">
                <span>
                    <i className="fas fa-search"/>
                </span>
             </button> */}
           
          {/* </form > */} 
          {/* <label htmlFor="box-2" id="search-form-opener">
            <span>
              <i className="fas fa-search"/>
            </span>
          </label>

          <label htmlFor="box-2" id="search-form-closer">
            <span>
              <i className="fas fa-times"/>
            </span>
          </label> */}
        {/* </div> */}
        
     
        
    </nav>
    
          
            
       {/* </div>      */}
       {/* <div className="Flex">
     
       
       </div> */}
         
        
    
    </div> 
    );
};

export default HeaderNavbar;