import React,{useState, useEffect} from 'react';

import './GetFavoris.scss';
import axios from 'axios';
import BigTitle from '../BigTitle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton  from "@mui/material/IconButton";
import Pagination from '../Pagination/Pagination';

const GetFavoris = ({title1, title2, title3, readLess, readMore}) => {
 
 const[favoris,setFavoris]=useState([])
 const [currentPage, setCurrentPage] = useState(1);
 const [articlesPerPage] = useState(12);
 const [currentPagePtr, setCurrentPagePtr] = useState(1);
 const [articlesPerPagePtr] = useState(12);
 const [currentPageCli, setCurrentPageCli] = useState(1);
 const [articlesPerPageCli] = useState(12);
 

const auth=JSON.parse(sessionStorage.getItem('userData'))
   const user_id=auth.user_id;
 
  const hostName="/Back-end/getFavoris";
 //const hostName="http://localhost/Back-end/getFavoris"
  useEffect(() => {
  getFavoris();
  }, []);
const getFavoris=()=>{
  
  axios
  .get(
    `${hostName}/${user_id}`
   
  )
  .then((reponse) => {
   console.log(reponse.data)
    setFavoris(reponse.data)
    
  });
}
 
  const study=[];
  const protocol=[];
  const clinical=[];
 favoris.forEach(element => {
  if (element.favoris==="study"){
    study.push(element)
  }else if  (element.favoris==="protocol"){
    protocol.push(element)
  }else {
    clinical.push(element)
  }
});

//connaitre la page courante Favoris Std
const indexOfLastArticle = currentPage * articlesPerPage;
const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
const currentArticles = study.slice(indexOfFirstArticle, indexOfLastArticle);
 // changement de page
 const paginate = pageNumber => setCurrentPage(pageNumber);


//connaitre la page courante Favoris ptr
const indexOfLastArticlePtr = currentPagePtr * articlesPerPagePtr;
const indexOfFirstArticlePtr = indexOfLastArticlePtr - articlesPerPagePtr;
const currentArticlesPtr = protocol.slice(indexOfFirstArticlePtr, indexOfLastArticlePtr);
 // changement de page
 const paginatePtr = pageNumber => setCurrentPagePtr(pageNumber);


//connaitre la page courante Favoris ptr
const indexOfLastArticleCli = currentPageCli * articlesPerPageCli;
const indexOfFirstArticleCli = indexOfLastArticleCli - articlesPerPageCli;
const currentArticlesCli = clinical.slice(indexOfFirstArticleCli, indexOfLastArticleCli);
 // changement de page
 const paginateCli = pageNumber => setCurrentPageCli(pageNumber);

  return (
    <section className='favorisSection'>
    <div className='favoris'>
    <BigTitle
    text=""
    title=""
    span={title1}
    direct="left"
  />

 <div className='flexCard'>
{(currentArticles)&&
              currentArticles.map((st) => {
                return ( 
        
			<Card key={st.id} abstract={st.abstract} title={st.title} full_url={st.full_url} authors={st.authors} readMore={readMore} readLess={readLess} url_img={st.url_img}
      id={st.id}
      />
)
        })}  
  </div>
   
  <Pagination
                    articlesPerPage={articlesPerPage}
                    totalArticles={study.length}
                    paginate={paginate}
                    currentPage={currentPage}
                />

</div>


<div className='favoris'>
    <BigTitle
    text=""
    title=""
    span={title2}
    direct="left"
  />

<div className='flexCard'>
{(currentArticlesPtr)&&
              currentArticlesPtr.map((st) => {
                return ( 
			<Card key={st.id} id={st.id}  abstract={st.abstract} title={st.title} full_url={st.full_url} authors={st.authors} url_img={st.url_img} readMore={readMore} readLess={readLess} />

)
        })}   
</div>
<Pagination
                    articlesPerPage={articlesPerPagePtr}
                    totalArticles={protocol.length}
                    paginate={paginatePtr}
                    currentPage={currentPagePtr}
                />
</div>
<div className='favoris'>
    <BigTitle
    text=""
    title=""
    span={title3}
    direct="left"
  />
   <div className='flexCard'>
{(currentArticlesCli)&&
              currentArticlesCli.map((st) => {
                return ( 
			<Card  key={st.id} id={st.id} abstract={st.abstract} title={st.title} full_url={st.full_url} authors={st.authors} url_img={st.url_img} readMore={readMore} readLess={readLess}/>

)
        })}   

</div>
<Pagination
                    articlesPerPage={articlesPerPageCli}
                    totalArticles={clinical.length}
                    paginate={paginateCli}
                    currentPage={currentPageCli}
                    />
                    
</div>
</section>

  );
};

const Card = ({ abstract,
  title,
  full_url,
  readMore,
  readLess,  
  id,
  authors,
  url_img}) => {
    const [show, setShow] = useState(false)
  
  //const hostName="/Back-end/deleteFavoris";
const hostName="http://localhost/Back-end/deleteFavoris";
 const deleteFavoris=(id)=>{
  axios
     .delete(
       `${hostName}/${id}`
      
     )
     .then((reponse) => {
      
      window.location.reload(false); 
       
       
     });

 }
  
  return (
<div className="card borderCard" style={{width:"24rem"}}>
          <div className="card-header headerFlex" style={{height:"8rem"}}>
              <img src={url_img}/>
              <span className="titleHeader"> {title}</span>
            </div>
            <div className="card-body">
            {/* <div style={{height:"5rem", marginBottom:"15px"}}> */}
            <h6 className="card-subtitle mb-2 mt-2 text-muted">{authors}</h6>
              {/* </div> */}
              {abstract != "" ? (
                <p>{show ? abstract : abstract.substr(0, 100) + "....."}</p>
              ) : (
                <p></p>
              )}
              <span className="buttonRead color" onClick={() => setShow(!show)}>
                <span className="hyphen"></span>
                <span className="buttonRead_text">
                  {" "}
                  {!show ? readMore : readLess}
                </span>
              </span>
            </div>
            <div className="card-footer flex">
              <a
                className="center"
                href={`https://mad-up.com/api/uploads/mad-up/originals/${full_url}?download`}
                target="_blank"
                download="Your File.pdf"
              >
                <IconButton aria-label="download">
                  <i className="fa-solid fa-download"></i>
                </IconButton>
              </a>
              
                <IconButton aria-label="add to favorites" onClick={()=>deleteFavoris(id)}>
                  <DeleteForeverIcon />{" "}
                </IconButton>
            
            </div>
</div>
  );
};

export default GetFavoris;