import React , { useState, useEffect, useRef }from 'react'
import { Navigate, useParams, useNavigate, Link} from "react-router-dom";
import Meta from "../../Components/Meta";
import * as dataElearning from "../../Data/Elearning.json";
import "./Elearning.scss";
import axios from "axios";
import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount"
import Breadcrumb from '../../Components/Breadcrumb';
import SideBar from '../../Components/SideBar/SideBar';
import Footer from '../../Components/Footer';
import ReactPlayer from 'react-player';
import { Formik, Form } from "formik";
const ElearningDetail = () => {
  const {lang} = useParams ();
  const {slug}= useParams();
  const {id}=useParams();
  const[cpt, setCpt]=useState(0)
  const [data, setData] = useState(dataElearning.default[lang]);
  const[video, setVideo]=useState([]);
  const[idV,setIdV]=useState([])
  const[array,setArray]=useState([])
  const[pourc,setPourc]=useState(0);
  const navigate = useNavigate();
  const [meta, setMeta] = useState({
      title: dataElearning.default[lang].meta.title,
      description: dataElearning.default[lang].meta.description,
      canonical: dataElearning.default[lang].meta.canonical,
      image: dataElearning.default[lang].meta.image,
      meta: {
          charset: "utf-8",
          name: {
              keywords: dataElearning.default[lang].meta.keywords,
          }

      },
  });

  useEffect(() => {
      setData(dataElearning.default[lang]);
      setMeta({
          title: dataElearning.default[lang].meta.title,
          description: dataElearning.default[lang].meta.description,
          canonical: dataElearning.default[lang].meta.canonical,
          keywords: dataElearning.default[lang].meta.keywords,
          image: dataElearning.default[lang].meta.image
      });
  }, [lang]);



  useEffect(()=>{
    axios.get(`https://mad-up.com/api/mad-up/items/elearning?fields=id,category_title.*,duration_video,category_title.id,translations.*,translations.video.*,translations.title,translations.language&lang=${lang},category_title.slug&filter[category_title.slug][eq]=${slug}`
   )
  .then(reponse => {
    setIdV(reponse.data.data);
    setArray([...idV.map(i=>i.id)])
    
  })
  }
  ,[lang, idV])
  
  useEffect(()=>{
    for(let i=0; i<array.length; i++){
       if (Number(id)===array[i]){
         setCpt(i)
        
       }
      }
      setPourc(100/array.length)
   },[array])

useEffect(()=>{
  axios.get(`https://mad-up.com/api/mad-up/items/elearning?fields=id,category_title.*,duration_video,category_title.id,translations.*,translations.video.*,translations.title,translations.language&lang=${lang},category_title.slug&filter[category_title.slug][eq]=${slug}&filter[id]=${id}`
 )
.then(reponse => {
  setVideo(reponse.data.data);
 // console.log(reponse.data.data)
  

})
}
,[lang,slug, id])



const updateSelectCategory=()=>{
      if((cpt<array.length-1)){
        setCpt(cpt+1);
        setPourc((100/array.length))

      }
      else{
        setTimeout(() => {
          navigate(`/${lang}/E-learning/${slug}`)  
        }, 1000)
        
      }
}
useEffect(()=>{
  if(cpt){
    navigate(`/${lang}/E-learning/${slug}/${array[cpt]}`,{ replace: true })
  }
  
}

,[cpt, pourc])
  const authUser=JSON.parse(sessionStorage.getItem('userData'));
  if(authUser===null){
    return <Navigate to={`/${lang}/connexion`} />;
  }
  const hostName="/Back-end/addElearning";
  //const hostName="http://localhost/Back-end/addElearning"
   const handleSubmit = (data) => {
   
        axios.post(hostName,data)
        
       .then((response)=>{
         //console.log(response)
       }).catch(error=>{
         console.log(error)
       })
     
   };

  return (
    <>
          <Meta meta={meta} />
   <HeaderAccount />
      <Breadcrumb title={data.breadcrumb} />
        <SideBar>
        <Formik
        initialValues={{
          pourc:pourc,
          slug:slug,
          status:true,
          idV:array[cpt],
          user_id:authUser["user_id"],
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
        handleSubmit(values)
        console.log(values);
        }}
      >
         {({ values }) => (
          <Form>
          {video && video.map(v=>{
            return (
              <div  className='video'
            key={v.id}>
                <ReactPlayer
          
            url={v.translations[0].video.data.full_url}
            controls
            width="100%"
            height="100%"
            type="video/mp4"
            onSeek={e => console.log('onSeek', e)}
          />
          
              </div>
            )
          })}
            
             
             <div className='buttonNavigation'>
            
            
            <Link to={`/${lang}/E-learning/${slug}`} className="linkPre">{data.textPrevious}</Link>
            <button  className='BtnElearning' onClick={updateSelectCategory} type="submit">{data.next}</button>
            {/* <Link to="" className='link'>{data.textNext}</Link> */}
          </div>
  
             </Form>
               )}
             </Formik>
      </SideBar>
               
      <Footer/>
    </>
  );
}

export default ElearningDetail