import React, { useState, useEffect } from "react";
import { withRouter, Redirect, Link } from "react-router-dom";
import "./UpdateInterest.scss";
import axios from "axios";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import BigTitle from "../BigTitle";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon
} from 'mdb-react-ui-kit';

export const UpdateInterest = (props) => {
  const [basicModal, setBasicModal] = useState(false);
  // const[interset, setInterset]=useState([]);

  const toggleShow = () => {setBasicModal(!basicModal)
    
  };
 //const hostName2="/Back-end/getInterest";
  // const hostName2="http://localhost/Back-end/getInterest"
  // useEffect(() => {
  //   axios
  //     .get(
  //       `${hostName2}/${auth["user_id"]}`)
  //     .then((reponse) => {
  //       setInterset(reponse.data);
  //      //console.log(reponse.data)
  //     });
  // }, []);
  // console.log(interset)
  let interest=[]
  interest=JSON.parse(sessionStorage.getItem('interest'));
  console.log(interest[0].sante)

  let auth=[];
  auth=JSON.parse(sessionStorage.getItem('userData'))

  const initialValues = {
    epaule:"",
    genou:"",
    coude:"",
    hanche:"",
    cheville_pied:"",
    poignet_main:"",
    sport:"",
    sante:"",
    reeducation:"",
    user_id:auth["user_id"]
  };
  

const hostName="/Back-end/UpdateInterest";
//const hostName="http://localhost/Back-end/UpdateInterest"
const handleSubmit = (dataInterest) => {
     axios.put(hostName,dataInterest)
     
    .then((response)=>{
      console.log(response.data)   
      window.location.reload(); 
    }).catch(error=>{
      console.log(error)
    })
  
};
  return (

   <div className="sectionInterest">
    
     <button  className="submitButton btn"  onClick={toggleShow}>
              {props.ButtonText2}
            </button>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
        <MDBModalDialog style={{margin:"200px auto auto",maxWidth:800}}>
          <MDBModalContent style={{borderRadius:"25px", border:"1px solid #15acf2",paddingLeft:"10px", fontWeight:"bold"}}>
            <MDBModalHeader style={{border:"none", paddingBottom:"0px"}}>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>

            <Formik
        initialValues={initialValues}
        onSubmit={(interData) => {
          handleSubmit(interData);
          console.log(interData)
          
        }}
      >
        {({ handleChange, handleBlur, values, setFieldValue, handleReset }) => (
          <Form>
          
            <p>{props.text1}</p>
            <div className='intersetDiv'>

           <label>
              <Field type="checkbox" name="sport"  value={props.span1}/>
              <span>{props.span1}</span>
            </label>
          

      
              
            <label  
                >
              <Field type="checkbox" name="sante"  value={props.span2}
            
              />
              <span>{props.span2}</span>
            </label>
            <label  
                >
              <Field type="checkbox" name="reeducation"  value={props.span3}
             
              />
              <span>{props.span3}</span>
            </label>
            </div>
            <p>{props.text2}</p>
            <div className='intersetDiv'>
            <label
                >
              <Field type="checkbox" name="epaule"  value={props.span4}
              />
              <span>{props.span4}</span>
            </label>
            <label  
                >
              <Field type="checkbox" name="genou"  value={props.span5}
            
              />
              <span>{props.span5}</span>
            </label>
            <label  
                >
              <Field type="checkbox" name="coude"  value={props.span6}
             
              />
              <span>{props.span6}</span>
            </label>
            </div>
            <div className='intersetDiv'>
            <label 
                >
              <Field type="checkbox" name="hanche"  value={props.span7}
            
              />
              <span>{props.span7}</span>
            </label>
            <label 
                >
              <Field type="checkbox" name="cheville_pied"  value={props.span8}
            
              />
              <span>{props.span8}</span>
            </label>
            <label
                >
              <Field type="checkbox" name="poignet_main"  value={props.span9}
             
              />
              <span>{props.span9}</span>
            </label>
            </div>
           
            <button type="submit" className="submitButton">
              {props.ButtonText}
            </button>
               
    
          </Form>
        )}
      </Formik>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
   </div>
   
  );
};

export default UpdateInterest;
