import React, {useState, useEffect} from "react";
import "./HomePage.scss";
import {Link, useParams} from "react-router-dom";
import * as dataJSON from "../../Data/HomePage.json";
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand
  } from 'mdb-react-ui-kit';
  import Language from "../../Components/Languages";
import ReactPlayer from 'react-player'
const HomePage = (props) => {
    let {lang} = useParams();

    const [data, setData] = useState(dataJSON.default[lang]);

    useEffect(() => {
        setData(dataJSON.default[lang]);
    }, [lang]);
    return (
        < div className="homePage">
           <header >
      <MDBNavbar light bgColor='white' expand='lg' className='me-2'>
          <MDBContainer>
            <MDBNavbarBrand href='#'>
              <img
                src="https://mad-up.com/api/uploads/mad-up/originals/traLogoMADAcademy.png"
                height='50'
                alt='Logo MADUP'
                loading='lazy'
              />
            </MDBNavbarBrand>
            <div className='d-flex w-auto mb-3'> <Language/></div>
          </MDBContainer>
        </MDBNavbar>
    </header>  
    <section className="flexHome">
    <ReactPlayer
          className='react-player '
          url='https://mad-up.com/api/uploads/mad-up/originals/madupWorkShop.mp4'
         
          loop
          playing
         width="100%"
         height="100%"
          
          muted
         // controls
         
         
        />  
      <div>

      <img
        src="https://mad-up.com/api/uploads/mad-up/originals/traLogoMADAcademy.png"
        alt='Logo MADUP'
        loading='lazy'
      />
			{data.text.map((text,index)=>
        <p className="pHome" key={index}>{text}</p>
      )
      }
      <p className="pPlatforme">{data.textP}</p>
      <Link
       
        to={`/${lang}${data.SLUG}`}
        className="linkButton text-uppercase "
      > 
          {data.textLink}
       
      </Link>
      </div>
			
     </section>
     
        </div>
    );
};
export default HomePage;
