import React from 'react';
import {
  MDBCard,
  MDBCardFooter,
  MDBCardBody,
  MDBCardImage
} from 'mdb-react-ui-kit';

export default function MediaLibraryCard({filename_disk, url}) {
  return (
          
            <MDBCard className='borderCard'>
        <MDBCardBody style={{margin:"auto"}}>
      {/* <MDBCardImage src={`https://mad-up.com/api/uploads/mad-up/originals/${url}`} alt={filename_disk} position='top' /> */}
      <embed src={`https://mad-up.com/api/uploads/mad-up/originals/${url}`} height="300" width="auto"/>
      </MDBCardBody>
      <MDBCardFooter>
        <a
            className="center"
            href={`https://mad-up.com/api/uploads/mad-up/originals/${filename_disk}?download`}
            target="_blank"
            download="Your File.pdf"
            >
        
            <i className="fa-solid fa-download"></i>
        </a>

      </MDBCardFooter>
    </MDBCard>
  );
}