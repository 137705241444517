import React from 'react'
import CreateAccount from "../../Components/CreateAccount/CreateAccount"

import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand
} from 'mdb-react-ui-kit';
import Language from "../../Components/Languages"
const UserAccount= (props) => {
  
  return (
    <>
     <header >
      <MDBNavbar light bgColor='white' expand='lg' className='me-2'>
          <MDBContainer>
            <MDBNavbarBrand href='#'>
              <img
                src="https://mad-up.com/api/uploads/mad-up/originals/traLogoMADAcademy.png"
                height='50'
                alt='Logo MAD-UP Academy'
                loading='lazy'
              />
            </MDBNavbarBrand>
            <div className='d-flex w-auto mb-3'> <Language/></div>
          </MDBContainer>
        </MDBNavbar>
    </header>
   
      <CreateAccount/>
   
  
  
    </>
  )
}

export default UserAccount