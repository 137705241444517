import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import * as Yup from 'yup';
import * as dataJSON from "../../Data/Users.json";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ModifyUser from '../../Components/ModifyUser/ModifyUser'

import "./Users.scss"
const  UserList= (props) => {
  const { lang } = useParams();
  const[userList, setUserList]=useState([]);
  const [data, setData] = useState(dataJSON.default[lang]);
  const hostName="/Back-end/getUsers";
  
useEffect(() => {
  axios
    .get(
      `${hostName}`)
    .then((reponse) => {
      setUserList(reponse.data);
     console.log(reponse.data)
     sessionStorage.setItem('allusers',JSON.stringify(reponse.data))
    });
}, []);

return(
  <>  
  <table>
    <tr className='info'>
      <th>{data.tab.name}</th>
      <th>{data.tab.role}</th>
      <th>{data.tab.company}</th>
      <th>{data.tab.country}</th>
      <th>{data.tab.city}</th>
      <th>{data.tab.action}</th>
    </tr>
    {
      userList.map(user=>{
        return (
          <UserItem 
            key={user.user_id}
            reponse={data.user.reponse}
            last_name={user.last_name} 
            first_name={user.first_name} 
            role={user.role} company={user.company_name} 
            country={user.country} town={user.town} 
            phone={user.phone_number}
          />
        )
      }) 
    }

    <Link to={`/${props.lang}/Devices`}>{props.textDevice} </Link>
  </table>      
  </>
)};

const UserItem=({reponse, user_id, last_name, first_name, phone, role, company, country, town})=>{
  const { lang } = useParams();
  const [data] = useState(dataJSON.default[lang]);
  const [status, setStatus] = useState(false);

  const [editBasicModal, setEditBasicModal] = useState(false);
  const editShow = () => setEditBasicModal(!editBasicModal);
  const hostNameSaveUser="/Back-end/updateUser";
  const handleSubmitEdit = (userData) => {
    axios
      .post(hostNameSaveUser, userData)
      .then((response) => {
        console.log(response.data);
        console.log(response.data.message)
        if (response.data.status === true) {
          sessionStorage.setItem(
            "userData",
            JSON.stringify(response.data.userData)
          );
          // document.querySelector(".message").innerHTML = props.messageResponse;
          // document.querySelector(".message").classList.add("reponseTrue");
          setStatus(true);
        } else {
          // document.querySelector(".message").innerHTML = props.messageError;
          // document.querySelector(".message").classList.add("reponseError");
          setStatus(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(data.user.errMsg),
    last_name: Yup.string().required(data.user.errMsg)
    });

  return(
    <tr className='info'>
      <th>{first_name} {last_name}</th>
      <th>{role}</th>
      <th>{company}</th>
      <th>{country}</th>
      <th>{town}</th>
      <th>
        <ModifyUser
          user_id={user_id}
          reponse={reponse}
          last_name={last_name}
          first_name={first_name} 
          role={role} company={company} 
          country={country} town={town} 
          phone={phone}/>
      </th>
    </tr>
  )
}

export default UserList;