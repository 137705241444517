import React,{useState, useEffect} from 'react';
import { withRouter,Redirect, useParams, Link} from "react-router-dom";
import './ModifyUser.scss';
import axios from 'axios';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as dataJSON from "../../Data/Users.json";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon
} from 'mdb-react-ui-kit'

export const ModifyDevice = ({user_id, last_name, first_name, phone, role, company, country, town}) => {
 
    const { lang } = useParams();
    const [status, setStatus] = useState(false);
    const [basicModal, setBasicModal] = useState(false);
    const [data, setData] = useState(dataJSON.default[lang]);
  
    const toggleShow = () => {
      setBasicModal(!basicModal);
    };

    let auth=[];
    auth=JSON.parse(sessionStorage.getItem('userData'))

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required(data.user.errMsg),
        lastname: Yup.string().required(data.user.errMsg)
    });    
   
    const initialValues = {
        userId: user_id,
        firstname: first_name,
        lastname: last_name,
        role: role,
        phone_number: phone,
        company_name: company,
        effectif: 0,
        address: "",
        town: town,
        postal_code: "",
        country: country,
        num_cabinet: ""
    };      

    const hostName="/Back-end/updateUser";
    const handleSubmit = (dataDevice) => {
        axios.post(hostName,dataDevice)
        .then((response)=>{
            console.log(response.data);
            if(response.data.status === false)
            {
                document.getElementById("errorSN").value = response.data.msg;
            }
            else
            {
                document.getElementById("errorSN").value = "";
                window.location.reload();
            }
            }).catch(error=>{
                console.log(error)
            })
    };
      
    return (
        <>
            <MDBIcon onClick={toggleShow} fas icon="pencil"/>
                <div className='modalAdd'>
            <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
                <MDBModalDialog  style={{margin:"200px auto auto",maxWidth:1000}}>
                    <MDBModalContent style={{borderRadius:"25px", border:"1px solid #15acf2",paddingLeft:"10px", fontWeight:"bold"}}>
                        <MDBModalHeader style={{border:"none", paddingBottom:"0px"}}>
                            <MDBModalTitle style={{textTransform:"uppercase" , fontWeight:"bold"}}>{data.user.title}</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>  <p className='error'></p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(userData)=>{
                  handleSubmit(userData);}}
              >
                {({ 
                  handleChange,
                  handleBlur,
                  values,
                  setFieldValue,
                  handleReset
                   }) => (
                  <Form className='formDevice'>
                    <div className="form-group mb-3">
                        <label htmlFor="firstname" >{data.user.firstName}</label>
                        <Field type="text" id="firstname" name="firstname"/>
                        <ErrorMessage name="firstname" component="small" className="text-danger"/>
                    </div>
                    <div className=" form-group mb-3">
                        <label htmlFor="lastname">{data.user.lastName}</label>
                        <Field type="text" id="lastname" name="lastname"/>
                        <ErrorMessage name="lastname" component="small" className="text-danger"/>
                    </div>
                    <div className=" form-group mb-3">
                        <label htmlFor="phone_number">{data.user.phone}</label>
                        <Field type="text" id="phone_number" name="phone_number"/>
                        <ErrorMessage name="phone_number" component="small" className="text-danger"/>
                    </div>
                    <div className=" form-group mb-3">
                        <label htmlFor="role">{data.user.role}</label>
                        <Field type="text" id="role" name="role"/>
                        <ErrorMessage name="role" component="small" className="text-danger"/>
                    </div>
                    <div className=" form-group mb-3">
                        <label htmlFor="company_name">{data.user.company}</label>
                        <Field type="text" id="company_name" name="company_name"/>
                        <ErrorMessage name="company_name" component="small" className="text-danger"/>
                    </div>
                    <MDBModalFooter className="d-flex justify-content-between align-items-start" style={{border:"none", paddingTop:"0px"}}>
             
             <button type="submit" style={{background:"#15acf2", border:"none", width:"150px", color:"#fff", padding:"10px", borderRadius:"10px", fontWeight:"bold"}}>{data.user.buttonSave}</button>
                    
            
            </MDBModalFooter>
            <p className='msg'></p>
                  </Form>
                )}
              </Formik>
              </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      </div>      
          </>
      )
    };

export default ModifyDevice;
