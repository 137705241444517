import React, { useState} from "react";
import "./Elearning.scss";
const CardList = ({
   description,
    title,
    img,
    text,
    url_img,
    pourc}) => {
    return (
  <div className="card borderCard">
            <div className="card-header headerFlex" style={{height:"10rem"}}>
                <img src={url_img} alt="img"/>
                <span className="titleHeader">{title}</span>
              </div>
              <div className="card-body" >
             
                <p>{ description.substr(0, 150) + "....."}</p>
              </div>
              <div className="card-footer d-flex justify-content-between">
              <p><img src={img} className="imgPicto" alt="picto"/> Mad-up Academy</p>
              <p className="fw-bold">{pourc} {text}</p>

                </div>
  </div>
    );
  };
  export default CardList;