import React, { useState, useEffect} from "react";
import { NavLink, useParams ,Navigate} from "react-router-dom";
import {
    CgMenuRight
  
}from "react-icons/cg";
import "./SideBar.scss";
import * as dataJSON from "../../Data/NavBarAccount.json";
const SideBar = ({children}) => {
  const {lang} = useParams();
  const [data, setData] = useState(dataJSON.default[lang]);
  useEffect(() => {
    setData(dataJSON.default[lang]);
  }, [lang]);

  const Logout = () => {
   // alert("clic")
     sessionStorage.removeItem("userData");
     sessionStorage.clear()
    
  };

  let auth=[];
  auth=JSON.parse(sessionStorage.getItem('userData'))
   const role=auth.role;
  
  const[isOpen ,setIsOpen] = useState(false);
  const toggle = () => setIsOpen (!isOpen);
  let activeStyle = {
    backgroundColor: "#15acf2",
    color: "white",
  };
  return (
    <div className="containerFlex sideBarSection">
    <div style={{width: isOpen ? "70px" : "300px"}} className="sidebar">
    
            <div style={{marginLeft: isOpen ? "0px" : "50px"}} className="bars">
                <CgMenuRight onClick={toggle}/>
            </div>
        <h1 style={{display: isOpen ? "none" : "block"}} className="logo"><i className="fa-solid fa-graduation-cap"></i></h1>
         <aside>
          <nav>
        {
            data.sideBar.map((item, index)=>{
                
                if(index===7){
                  return (
                    <NavLink to={`/${lang}${item.SLUG}`} key={index} 
                   className="link" 
                     onClick={()=> Logout()}
                     >
                    <i className={item.icon} id="icon" />
                   <div  style={{display: isOpen ? "none" : "block"}}  className="link_text">{item.text}</div>
               </NavLink>
                  );
                }
                if(index===2){
                  return (
                    <NavLink to={`/${lang}${item.SLUG}`} key={index} className="link" 
                    style={({ isActive }) =>
                isActive ? activeStyle : undefined
                 }
                >
                  <img src="https://mad-up.com/api/uploads/mad-up/originals/mad-upPRo.svg" alt="mad-up pro" className="imgpro"/>
                   <div  style={{display: isOpen ? "none" : "block"}}  className="link_text">{item.text}</div>
               </NavLink>
                  );
                }

                if(index===5)
                {
                  if(role === "Admin")
                  {
                    return (
                      <NavLink to={`/${lang}${item.SLUG}`} key={index} className="link" 
                      style={({ isActive }) =>
                  isActive ? activeStyle : undefined
                    }
                  >
                  <i className={item.icon} id="icon" />
                    <div style={{display: isOpen ? "none" : "block"}} className="link_text">{item.text}</div>
                  </NavLink>
                    );
                  }
                  else
                  {
                    return;
                  }
                } 
                else {
                  if(index===6)
                  {
                    if(role === "Admin")
                    {
                      return (
                        <NavLink to={`/${lang}${item.SLUG}`} key={index} className="link" 
                        style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                      }
                    >
                    <i className={item.icon} id="icon" />
                      <div style={{display: isOpen ? "none" : "block"}} className="link_text">{item.text}</div>
                    </NavLink>
                      );
                    }
                    else
                    {
                      return;
                    }
                  } 
                  else
                  {
                    return(

                      <NavLink to={`/${lang}${item.SLUG}`} key={index} className="link" 
                      style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                       }
                      >
                           <i className={item.icon} id="icon" />
                          <div style={{display: isOpen ? "none" : "block"}} className="link_text">{item.text}</div>
                      </NavLink>
                      )
                  }
                }
                
               
                
            })
        }
        </nav>
        </aside>
    </div>
    <main>{children}</main>
 </div>
   

  );
}
export default SideBar;