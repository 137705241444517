import React,{useState} from 'react';
import { Link} from "react-router-dom";

import './recupMail.scss';
import axios from 'axios';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
const MailRecup = ({email, text, TexTReturn, SLUGReturn, TextSLUG, SLUG, messageError, message}) => {
  
  const [status, setStatus] = useState("");
  //const hostName="http://localhost/Back-end/forgotPassword";
  const hostName="/Back-end/forgotPassword";
   
    const validationSchema = Yup.object().shape({

        email: Yup.string()
          .required("Email obligatoire")
          .email("Email non valide"),
      });
    
    
    
      const initialValues = {  
          "email": "",
      };
      const handleSubmit = (email) => {
          
           axios.post(hostName,email)
          .then((response)=>{
            console.log(response)
            if (response.data.success===false){
              setStatus(messageError)
            } else{
             
              setStatus(message)
            }    
          }).catch(error=>{
            console.log(error)
          })
        
      };
     
      return (
    
        <div className="container">
          <div className="row password">
            <div className="col-md-6 offset-md-3 pt-3">
            <p className="message">{status}</p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(userData)=>handleSubmit(userData)}
              >
                {({ 
                  handleChange,
                  handleBlur,
                  values
                   }) => (
                    
                  <Form>
                    
           
                    <div className="form-group mt-5 mb-4">
                    {/* <p>{TextSpan}</p> */}
                      <label htmlFor="email">
                     {email}
                      </label>
                      <div className="input-group-prepend">
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        className="form-control activeInput "
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      
                      </div>
                     
                      <ErrorMessage
                        name="email"
                        component="small"
                        className="text-danger"
                      />
                    </div>

                    <div className="form-group flexBtn">
                    <button className="btn-return mt-4">
                    <Link to={SLUGReturn}
                        className='font-italic text-white'
                      >{TexTReturn}</Link>
                      </button>
                      <button
                        type="submit"
                        className="btn btn-submit mt-4 "
                      >
                        {text}
                      </button>
                    </div>
                   
                    <div className="form-group d-flex justify-content-center gap-3 m-5">
                      <Link to={SLUG}
                        
                        className="colorLink link mt-3"
                      >{TextSLUG}
                      </Link>
                      
                      
                    </div>
                   
                    
                   
                  </Form>
                )}
              </Formik>
              
             
            </div>
              
          </div>
          
        </div>
    
      )
    };

export default MailRecup;
