import React , { useState, useEffect }from 'react'
import {  Navigate, useParams} from "react-router-dom";
import BigTitle from "../../Components/BigTitle";
import Meta from "../../Components/Meta";
import * as dataEvent from "../../Data/Notification.json";
import "./Notifications.scss";

import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount"
import Breadcrumb from '../../Components/Breadcrumb';
import SideBar from '../../Components/SideBar/SideBar';
import Footer from '../../Components/Footer';
 const Notifications= (props) => {
  const {lang} =useParams();
  const [data, setData] = useState(dataEvent.default[lang]);
  



  const [meta, setMeta] = useState({
      title: dataEvent.default[lang].meta.title,
      description: dataEvent.default[lang].meta.description,
      canonical: dataEvent.default[lang].meta.canonical,
      image: dataEvent.default[lang].meta.image,
      meta: {
          charset: "utf-8",
          name: {
              keywords: dataEvent.default[lang].meta.keywords,
          }

      },
  });

  useEffect(() => {
      setData(dataEvent.default[lang]);
      setMeta({
          title: dataEvent.default[lang].meta.title,
          description: dataEvent.default[lang].meta.description,
          canonical: dataEvent.default[lang].meta.canonical,
          keywords: dataEvent.default[lang].meta.keywords,
          image: dataEvent.default[lang].meta.image
      });
  }, [lang]);

  const[auth,setAuth]=useState([]);
  useEffect(()=>{
      
      const authUser=JSON.parse(sessionStorage.getItem('userData'));
      setAuth(authUser);
  },[])

  if(auth===null){
    return <Navigate to={`/${lang}/connexion`} />;
  }


  return (
    <>
     
    <Meta meta={meta} />
   <HeaderAccount />
    {/* <BigTitle
        text=""
        title={data.bigtitle.title}
        span={data.bigtitle.span}
        direct="left"
    /> */}
      <Breadcrumb title={data.breadcrumb} />

            <div>
            
                <SideBar>
               
                <h2>{data.textP}</h2>
               
                </SideBar>
                </div>
   <Footer/>
    </>
  )
}

export default Notifications;