import React from 'react';
import ReactDOM from 'react-dom';
import { Formik, Field, Form } from 'formik';
import axios from 'axios';


const Feedback = ({textPlaceholder, reponse, send}) => {
    let auth=[];
    auth=JSON.parse(sessionStorage.getItem('userData'))
    const initialValues = {  
        email: auth["email"],
        feedback: "",
        firstName:auth["first_name"],
        lastName:auth["last_name"]
      };
     const hostName="/Back-end/Feedback";
   //const hostName="http://localhost/Back-end/Feedback"
   const handleSubmit = (values) => {
        axios.post(hostName,values)
       .then((response)=>{
        if (response.status===200){
            document.querySelector(".question").innerHTML=reponse;
            window.location.reload()
        }
         
       }).catch(error=>{
         console.log(error)
       })
     
   };
    return(
        <div>
            <p className='question'></p>
        <Formik
          initialValues={initialValues}
          onSubmit={ (values) => {
            handleSubmit(values)
          //alert(values)
          }}
        >
          <Form className='formFed'>
          

            <Field
              id="feedback"
              name="feedback"
              placeholder={textPlaceholder}
              as='textarea'
              rows="2" cols="25"
              className="textArea"
            />
            {/* <button  >{send}</button> */}
            <button className="buttonRead color buttonFed" type="submit">
                <span className="hyphen"></span>
                <span className="buttonRead_text">
                 {send}
                </span>
              </button>
            
          </Form>
        </Formik>
      </div>
    )
}
export default Feedback;

