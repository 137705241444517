import React, { useState } from "react";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem ,MDBBtn} from 'mdb-react-ui-kit';
import "./Language.scss";
import { useLocation, useParams,useNavigate } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 50,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '2px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));
const Language = (props) => {
    const url = useLocation();
     const {lang} = useParams();
     let navigate = useNavigate();
    const replaceLanguage = (lang) => {
        const langHMTM = window.document.querySelector("html");
        let result = url.pathname.replace(/^\/[a-z][a-z]/g, `/${lang}`);
        langHMTM.setAttribute("lang", lang);
        navigate(result);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  

    return (
        <>
{/*              
            <MDBDropdown className="language">
                <MDBDropdownToggle  color="language">
                    <div className="dopdowrn language">{lang}</div>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                    <MDBDropdownItem link  text="FR"   onClick={() => replaceLanguage("fr")}
                            className="drop-btn">
                            FR
                        
                    </MDBDropdownItem>
                    <MDBDropdownItem link text="ENG" onClick={() => replaceLanguage("en")}
                            className="drop-btn">
                        
                            ENG
                        
                    </MDBDropdownItem>
                     {/* <MDBDropdownItem text="ES">
                        <div
                            onClick={() => replaceLanguage("es")}
                            className="drop-btn"
                        >
                            ES
                        </div>
                    </MDBDropdownItem> */}
                {/* </MDBDropdownMenu> */}
            {/* </MDBDropdown>   */}
            <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        // variant="contained"
        disableElevation
        style={{backgroundColor:"#15acf2", color:"#fff", padding:"10px 0", textAlign:"center"}}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {lang}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem  onClick={() => replaceLanguage("fr")} disableRipple>
           <div  onClick={handleClose}>FR</div> 
        </MenuItem>
        <MenuItem  onClick={() => replaceLanguage("en")}  disableRipple>
        <div  onClick={handleClose}> ENG </div>
        </MenuItem>
       
      </StyledMenu>
    </div>
           
        </>
    );
};

export default Language;
