import React from "react";

import { MDBContainer } from 'mdb-react-ui-kit';
import HeaderNavbar from "./HeaderNavbar";

export default (props) => {
    return (
        <>
       
            <header>
                    <HeaderNavbar {...props}/>
                </header>
       
           
        </>
    );
};
