import React, { useState, useEffect } from "react";
import * as dataJSON from "../../Data/NavBarAccount.json";
import "./BurgerAccount.scss";
import Navbar from "./Navbar.jsx"
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {Link,NavLink, useParams} from "react-router-dom";
const BurgerAccount = (props) => {
  const [open, setOpen] = useState(false);
  const showSideBar=()=>{
    setOpen(!open);
  }


  const { lang } = useParams();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const [data, setData] = useState(dataJSON.default[lang]);

  useEffect(() => {
    setData(dataJSON.default[lang]);
  }, [lang]);

  let activeStyle = {
    backgroundColor: "#15acf2",
    color: "white",
  };
  const authUser=JSON.parse(sessionStorage.getItem('userData'));
  return (
    <>
     <nav className="MobileNavigation">
        <div className="MobileNavbar">
              <div className={open ?"closeMenu":"openMenu"}>
                <Link to="#" className="menu-bars">
              <MenuIcon style={{color:"#05151e"}} sx={{ fontSize: 40 }} onClick={showSideBar}/>
              </Link>
              </div>
              <div className={open ?"openMenu":"closeMenu"}>
              <Link to="#" className="menu-bars">
              <CloseIcon style={{color:"#05151e"}} sx={{ fontSize: 40 }}  onClick={showSideBar}/>
              </Link>
              </div>
        </div>
        <nav className={open ?"nav-menu active ":"nav-menu"}>
        <ul onClick={showSideBar} className="navUl">
            {data.menu.map((item, id) => {
              const { SLUG, text } = item;
              return (
                <li key={id} className="nav-text">
                  <NavLink
                  
                    to={`/${lang}${SLUG}`}
                    exact="true"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                    onClick={() => {
                      scrollToTop();
                    }}
                  >
                    <span>{text}</span>
                  </NavLink>
                </li>
              );
            })}
            </ul>
        </nav>
     </nav>
    </>
  )
}

export default BurgerAccount;