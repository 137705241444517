import React,{useState} from 'react';
import {Navigate, Link} from "react-router-dom";
import './FormLogin.scss';
import axios from 'axios';
import * as Yup from 'yup';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';

export const FormLogin = ({email, password, text, src, textSpan, SLUG,TextSLUG, SLUGPassword,  errorActif , errorPassword, errorMail, ButtonText1}) => {
 
    const [status, setStatus] = useState(false);


    const [passwordShown, setPasswordShown] = useState(false);

   


    const validationSchema = Yup.object().shape({

        email: Yup.string()
          .required("Email obligatoire")
          .email("Email non valide"),
        password: Yup.string()
          .required("Mot de passe est obligatoire")
      });
    
    
    
      const initialValues = {  
          "email": "",
          "password": "",
      };
const hostName="/Back-end/loginUser";
 //const hostName="http://localhost/Back-end/loginUser"
      const handleSubmit = (loginData) => {
          //console.log(userData)
           axios.post(hostName,loginData)
          .then((response)=>{
            console.log(response.data)
           sessionStorage.setItem('userData',JSON.stringify(response.data.userData))
            if (response.data.status===true){
                setStatus(true);
                sessionStorage.setItem('userData',JSON.stringify(response.data.userData))
                
                // console.log(JSON.stringify(response.data.userData))

            }
            else{
                if (response.data.errorActif){
                  document.querySelector('.error').innerHTML=errorActif
                  document.querySelector(".error").classList.add("reponseError");
                }
                if (response.data.errorPassword){
                  document.querySelector('.error').innerHTML=errorPassword
                  document.querySelector(".error").classList.add("reponseError");
                
                } if (response.data.errorMail){
                  document.querySelector('.error').innerHTML=errorMail
                  document.querySelector(".error").classList.add("reponseError");
                }
                sessionStorage.removeItem("userData");
                sessionStorage.clear()

              }
            
          }).catch(error=>{
            console.log(error)
          })
        
      };
      if (status) {
        return <Navigate to={src} />;
      }
      if(sessionStorage.getItem('userData')){
        return <Navigate to={src} />;
      }
      return (
    
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 pt-3">
            <p className='error'></p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(userData)=>handleSubmit(userData)}
              >
                {({ 
                  handleChange,
                  handleBlur,
                  values
                   }) => (
                  <Form>
                    <div className="form-group mb-3">
                      <label htmlFor="email" className='mb-2'>
                     {email}
                      </label>
                     
                      <Field
                        type="email"
                        id="email"
                        name="email"
                       
                        className="form-control activeInput"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <ErrorMessage
                        name="email"
                        component="small"
                        className="text-danger"
                      />
                   
                    </div>
                    <div className="form-group mt-5">
                      <label htmlFor="password" className='mb-2'>
                       {password}
                      </label>

                      <Field
                        type={passwordShown ? "text" : "password"}
                        id="password"
                        name="password"
                     
                        className="form-control activeInput "
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        
                      />
                      <ErrorMessage
                        name="password"
                        component="small"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group d-flex justify-content-center gap-3 mb-4 mt-5">
                    
                      <Link to={SLUGPassword}
                        className='fst-italic '
                      >{textSpan}
                      </Link>
                    </div>
                    <div className="form-group d-flex justify-content-center gap-3 mb-4">
                      <button
                        type="submit"
                        className="btn colorButton "
                      >
                        {ButtonText1}
                      </button>
                    </div>

                    <div className="form-group d-flex justify-content-center gap-3 mb-4">
                      <Link to={SLUG}
                        
                        className="colorLink link mt-4"
                      >{TextSLUG}
                      </Link>
                      
                      
                    </div>
                    {/* <Link to="SendMail"
                        
                        className="colorLink"
                      >Envoi un mail
                      </Link> */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
    
      )
    };

export default FormLogin
