import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import "./UserProfile.scss";
import ReactLoading from "react-loading";
import BigTitle from "../BigTitle";
const UserProfile = (props) => {
  //const hostName="http://localhost/Back-end/updateUser";
  const hostName = "/Back-end/updateUser";

  const [status, setStatus] = useState(false);
  const handleSubmit = (userData) => {
  
    axios
      .post(hostName, userData)

      .then((response) => {
        console.log(response.data);
        console.log(response.data.message)
        if (response.data.status === true) {
          sessionStorage.setItem(
            "userData",
            JSON.stringify(response.data.userData)
          );
          document.querySelector(".message").innerHTML = props.messageResponse;
          document.querySelector(".message").classList.add("reponseTrue");
          setStatus(true);
        } else {
          document.querySelector(".message").innerHTML = props.messageError;
          document.querySelector(".message").classList.add("reponseError");
          
          setStatus(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let auth = [];
  if (
    JSON.parse(sessionStorage.getItem("userData")) !== null ||
    typeof sessionStorage != "undefined"
  ) {
    auth = JSON.parse(sessionStorage.getItem("userData"));
  } else {
    sessionStorage.removeItem("userData");
    sessionStorage.clear();
  }

  const formik = useFormik({
    initialValues: {
      firstName: auth["first_name"],
      lastName: auth["last_name"],
      email: auth["email"],
      role: auth["role"],
      phone_number: auth["phone_number"],
      company_name: auth["company_name"],
      effectif: auth["effectif"],
      address: auth["address"],
      town: auth["town"],
      postal_code: auth["postal_code"],
      country: auth["country"],
      num_cabinet: auth["num_cabinet"],
      civility: "",
      id_sellsy: auth["id_sellsy"],
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required!"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
      //alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <>
      {auth !== null || typeof sessionStorage != "undefined" ? (
        <div className="formUser">
          <form onSubmit={formik.handleSubmit} className="borderForm">
            <div>
              <BigTitle
                text=""
                title={props.h3ContactInformation}
                small=""
                direct="left"
              />
              <div className="form-group mb-3">
                <label htmlFor="lastName" >
                  {props.LabelLastName}
                </label>

                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  size="25"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className=" form-group mb-3">
                <label htmlFor="firstName">{props.labelFirstName}</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  //className="form-control fontSize"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* <div className="form-group mb-3">
                    <label htmlFor="email">
                     {props.LabelEmail}
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                     // className="form-control"
                      value={formik.values.email}
                      // onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      
                     
                    />
                    </div> */}
              <div className="form-group mb-3">
                <label htmlFor="phone_number">{props.LabelPhoneNumber}</label>
                <input
                  type="text"
                  id="phone_number"
                  name="phone_number"
                  //className="form-control fontSize"
                  value={formik.values.phone_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="address">{props.LabelAddress}</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                 /// className="form-control fontSize"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="town">{props.LabelTown}</label>
                <input
                  type="text"
                  id="town"
                  name="town"
                  //className="form-control fontSize"
                  value={formik.values.town}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="postal_code">{props.LabelCodePostal}</label>
                <input
                  type="text"
                  id="postal_code"
                  name="postal_code"
                 // className="form-control fontSize"
                  value={formik.values.postal_code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="country">{props.LabelCountry}</label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  //className="form-control fontSize"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div>
             
              <BigTitle
                text=""
                title={props.h3ProfessionalActivity}
                small=""
                direct="left"
              />
              <div className="form-group mb-3">
                <label htmlFor="role">{props.LabelRole}</label>
                <input
                  type="text"
                  id="role"
                  name="role"
                  //className="form-control fontSize"
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="company_name">{props.LabelCompanyName}</label>
                <input
                  type="text"
                  id="company_name"
                  name="company_name"
                 // className="form-control fontSize"
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>

              <div className="form-group mb-3">
                <label htmlFor="effectif">{props.LabelEffectif}</label>
                <input
                  type="text"
                  id="effectif"
                  name="effectif"
                 // className="form-control fontSize"
                  value={formik.values.effectif}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="num_cabinet">{props.LabelNum}</label>
                <input
                  type="text"
                  id="num_cabinet"
                  name="num_cabinet"
                 // className="form-control fontSize"
                  value={formik.values.num_cabinet}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>

            <div>
              {/* <h3>{props.h3Connection}</h3>
                  <div className="form-group mb-3">
                    <label htmlFor="email">
                     {props.LabelEmail}
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      value={formik.values.email}
                      // onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      
                     
                    /> */}
              {/* {formik.errors.email &&
                     formik.touched.email && <div className="input-feedback">{formik.errors.email}</div>} */}
              {/* </div> */}
            </div>
            <div className="form-group d-flex justify-content-end gap-3">
              <button
                type="button"
                className="outline btn btn-danger"
                onClick={formik.handleReset}
                disabled={!formik.dirty || formik.isSubmitting}
              >
                {props.ButtonText2}
              </button>
              <p className="message"></p>
              <button
                type="submit"
                className=" btn colorBtn"
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {props.ButtonText1}
              </button>
            </div>
          </form>
        </div>
      ) : (
        <ReactLoading type="spin" color="#15acf2" height={667} width={375} />
      )}
    </>
  );
};

export default UserProfile;
