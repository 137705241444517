import React , { useState, useEffect, useRef ,useCallback}from 'react'
import { Navigate, useParams , Link} from "react-router-dom";
import Meta from "../../Components/Meta";
import * as dataElearning from "../../Data/Elearning.json";
import "./Elearning.scss";
import axios from "axios";
import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount"
import Breadcrumb from '../../Components/Breadcrumb';
import SideBar from '../../Components/SideBar/SideBar';
import Footer from '../../Components/Footer';
import ReactLoading from 'react-loading';
import CardList from "./CardList";
 const Elearning= (props) => {
  const {lang} = useParams ();
  const [data, setData] = useState(dataElearning.default[lang]);
  const[title,setTitle]=useState([]);
  const[pourcentage,setPourcentage]=useState([]);
  const[newA,setNewA]=useState([{}])
  const [meta, setMeta] = useState({
      title: dataElearning.default[lang].meta.title,
      description: dataElearning.default[lang].meta.description,
      canonical: dataElearning.default[lang].meta.canonical,
      image: dataElearning.default[lang].meta.image,
      meta: {
          charset: "utf-8",
          name: {
              keywords: dataElearning.default[lang].meta.keywords,
          }

      },
  });

  useEffect(() => {
      setData(dataElearning.default[lang]);
      setMeta({
          title: dataElearning.default[lang].meta.title,
          description: dataElearning.default[lang].meta.description,
          canonical: dataElearning.default[lang].meta.canonical,
          keywords: dataElearning.default[lang].meta.keywords,
          image: dataElearning.default[lang].meta.image
      });
  }, [lang]);



useEffect(()=>{
    axios.get(`https://mad-up.com/api/mad-up/items/category_video?fields=id,image.*,slug,translation.*,translation.category,translation.langage&lang=${lang}`
   )
.then(reponse => {
    setTitle(reponse.data.data);
    //console.log(reponse.data.data)  
})
  }
,[lang])

      
  const auth=JSON.parse(sessionStorage.getItem('userData'));

const hostName="/Back-end/getElearning";
 //const hostName="http://localhost/Back-end/getElearning"
 useEffect(() => {
  getElerningById();
  
 },[]);
const getElerningById=()=>{
 
 axios
 .get(
   `${hostName}/${auth["user_id"]}`
  
 )
 .then((reponse) => {
  //console.log(reponse.data)
   setPourcentage(reponse.data)
   
 });
}
useEffect(()=>{
  title.map(t=>{        
              return (
                setNewA(oldArray => [...oldArray, {
                  id:t.id,
                  image:t.image,
                  slug:t.slug,
                  translation:t.translation,
                  pourc: pourcentage.map(p=>{
                    if(t.slug===p.slug){
                      return parseInt(p.pourc)
                    }
                  }).filter(notUndefined => notUndefined !== undefined)
                      
           
                     
               }])   
  )})
    
},[title, pourcentage])

console.log(newA)

  if(auth===null){
    return <Navigate to={`/${lang}/connexion`} />;
  }


  return (
    <>
     
    <Meta meta={meta} />
   <HeaderAccount />
      <Breadcrumb title={data.breadcrumb} />
        <SideBar>
        <section className='elearningList' >
        <div className='elearningCard'>
          {newA ? newA.map(t=>{
            if (t.id!=null){
              return(
                <Link to={`/${lang}/E-learning/${t.slug}`} key={t.id}> 
               <CardList  
                url_img={t.image.data.full_url} 
                title={t.translation[0].category}
                description={t.translation[0].description}
                readLess={data.readLess}
                readMore={data.readMore}
                pourc= {t.pourc.reduce(function(acc, val) { return acc + val; }, 0)}
                text={data.text}
                img={data.img}
                />  
                 </Link>
              )
            
            }
        }):
        <ReactLoading type="balls" color="#15acf2"  height={667} width={375} />
      }


        </div>
       </section>
       
        </SideBar>
               
   <Footer/>
    </>
  )
}

export default  Elearning