import React, { useState } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { Formik, Field, Form } from "formik";
import axios from "axios";
const ClincalCase = ({
  abstract,
  title,
  full_url,
  readMore,
  readLess,
  url_img,
  favoris
  
}) => {
  const [show, setShow] = useState(false);
  
  const [liked, setLiked] = React.useState(false);

  const handelLiked = () => {
    setLiked(!liked);
  };

  let auth = [];
  auth = JSON.parse(sessionStorage.getItem("userData"));

 const hostName="/Back-end/addFavoris";
//const hostName = "http://localhost/Back-end/addFavoris";
  const handleSubmit = (dataFavoris) => {
    axios
      .post(hostName, dataFavoris)

      .then((response) => {
          window.location.reload(false)
        // if (response.data.status==false){
        //   document.querySelector('.msgExist').innerHTML="Article exsite déja"
        //   document.querySelector(".msgExist").classList.add("exist");
        // }
        
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
   
      <Formik
        initialValues={{
          title: title,
          abstract: abstract,
          authors:"",
          full_url: full_url,
          favoris: "clincal_case",
          url_img:url_img,
          status:true,
          user_id: auth["user_id"],
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}>
      
        {({ values }) => (
          
          <Form>
            <div className="msgExist"></div>
            <div  className='card borderCard' style={{width:"auto"}}>
            <div className="card-header headerFlex" style={{height:"8rem"}}>
              <img src={url_img}/>
              <span className="titleHeader"> {title}</span>
            </div>
            <div className="card-body">
              {abstract != "" ? (
                <p>{show ? abstract : abstract.substr(0, 100) + "....."}</p>
              ) : (
                <p></p>
              )}
              <span className="buttonRead color" onClick={() => setShow(!show)}>
                <span className="hyphen"></span>
                <span className="buttonRead_text">
                  {" "}
                  {!show ? readMore : readLess}
                </span>
              </span>
            </div>
            <div className="card-footer flex">
              <a
                className="center"
                href={`https://mad-up.com/api/uploads/mad-up/originals/${full_url}?download`}
                target="_blank"
                download="Your File.pdf"
              >
                <IconButton aria-label="download">
                  <i className="fa-solid fa-download"></i>
                </IconButton>
              </a>
              {favoris  ?
                    
                    <IconButton aria-label="add to favorites" className="center" type="submit" onClick={handelLiked}>
                    <FavoriteIcon color="error" />
                  </IconButton>
                 :
                       <IconButton aria-label="add to favorites" onClick={handelLiked} type="submit">
                      <FavoriteIcon />
                    </IconButton>
                }
            </div>
            </div>
          </Form>
        )}
      </Formik>
     
    </>
  );
};
export default ClincalCase;
