import React , { useState, useEffect, useRef, useCallback }from 'react'
import { Navigate, useParams } from "react-router-dom";
import BigTitle from "../../Components/BigTitle";
import Meta from "../../Components/Meta";
import * as dataStudy from "../../Data/StudyScientific.json";
import "./Study.scss";
import axios from 'axios';
 import Scientific from "../../Components/scientific_studies/scientific_studie"
import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount"
import Breadcrumb from '../../Components/Breadcrumb';
import SideBar from '../../Components/SideBar/SideBar';
import Pagination from '../../Components/Pagination/Pagination';
import Footer from "../../Components/Footer";

 const ScientificStudies= (props) => {
  const {lang} =useParams();
  let offset=0;
  
  const [data, setData] = useState(dataStudy.default[lang]);
  const[studies, setStudies]=useState([]);
  const[category, setCategory]=useState([]);
  const[filterCategory,setFilterCategory]=useState(null)
  const[member,setMember]=useState(null)
  const[filterJoint,setFilterJoint]=useState(null)

  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(12);
  const [study, setStudy]=useState([]);
  const prevCategoryRef = useRef();
  const prevJointRef = useRef();

  const [meta, setMeta] = useState({
      title: dataStudy.default[lang].meta.title,
      description: dataStudy.default[lang].meta.description,
      canonical: dataStudy.default[lang].meta.canonical,
      image: dataStudy.default[lang].meta.image,
      meta: {
          charset: "utf-8",
          name: {
              keywords: dataStudy.default[lang].meta.keywords,
          }

      },
  });

  useEffect(() => {
      setData(dataStudy.default[lang]);
      setMeta({
          title: dataStudy.default[lang].meta.title,
          description: dataStudy.default[lang].meta.description,
          canonical: dataStudy.default[lang].meta.canonical,
          keywords: dataStudy.default[lang].meta.keywords,
          image: dataStudy.default[lang].meta.image
      });
  }, [lang]);

  const loadData=()=>{
    const category=filterCategory ?`filter[category]=${filterCategory}`:""
    const joint=filterJoint? `filter[member]=${filterJoint}`:""
    const url=`https://mad-up.com/api/mad-up/items/scientific_studies?fields=id,file.*,image.*,title,abstract,authors,member.slug,member.id,category.slug,category.id&${category}&${joint}`
   // console.log(url)
    axios.get(url)
    .then(reponse => {
        setStudies(reponse.data.data);
        // console.log(reponse.data.data)
    });
   
  }
//   const handelScroll=(e)=>{
//     const scrollHeight = e.target.documentElement.scrollHeight;
//     const currentHeight = Math.ceil(
//       e.target.documentElement.scrollTop + (window.innerHeight)
//     );
    
//     if (currentHeight +1 >= scrollHeight) {
//       loadData();
//     }
//   }
  useEffect(()=>{
    prevCategoryRef.current = filterCategory;
    prevJointRef.current=filterJoint;
    loadData()
    //window.addEventListener("scroll",handelScroll)

},[filterCategory, filterJoint])
  useEffect(()=>{
    
    axios.get(`
        https://mad-up.com/api/mad-up/items/category_filter?fields=id,sort,slug,translations.name,translations.language&lang=${lang}`)
    .then(reponse => {
        setCategory(reponse.data.data);
        //console.log(reponse.data.data)
        
      
    })
    
  },[lang])
  useEffect(()=>{
  
    axios.get(`
        https://mad-up.com/api/mad-up/items/member_filter?fields=id,sort,slug,translations.name,translations.language&lang=${lang}`)
    .then(reponse => {
        setMember(reponse.data.data);
       // console.log(reponse.data.data)
        
      
    })
   
  },[lang])
 //console.log(member)

    
const auth=JSON.parse(sessionStorage.getItem('userData'));
 
const selectCategory=(idCategory)=>{
       // console.log(`demande de la catégorie ${idCategory}`)
        setFilterCategory(idCategory)
        
        //loadData()
             
}
//console.log(filterCategory)

const selectJoint=(idJoint)=>{
   // console.log(`demande de l'articulation ${idJoint}`)
    setFilterJoint(idJoint)
    
}
  const hostName="/Back-end/getFavoris";
 //const hostName="http://localhost/Back-end/getFavoris"

const getFavoris=()=>{
  
  axios
  .get(
    `${hostName}/${auth["user_id"]}`
   
  )
  .then((reponse) => {
   console.log(reponse.data)
    setStudy(reponse.data)
    
  });
}
useEffect(() => {

    getFavoris();
  
 
  }, []);

if(auth===null){
    return <Navigate to={`/${lang}/connexion`} />;
  }

  //connaitre la page courante
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = studies.slice(indexOfFirstArticle, indexOfLastArticle);

// changement de page
const paginate = pageNumber => setCurrentPage(pageNumber);
  return (
    <>
     
    <Meta meta={meta} />
   <HeaderAccount />   
            <Breadcrumb title={data.breadcrumb} />
             
                <SideBar>
               
                <div >
                    <div className='filter'>
                       <label htmlFor="categorie">{data.textCategory}
                        <select className="select" name="category" id="category" onChange={(event)=>selectCategory(event.target.value)}>
                            <option value="" selected={filterCategory===null && "DefaultSelected"}>
                                {data.textOption}
                            </option>
                            {
                              category &&
                                category.map((category,id) =>{
                                    return <option key={id}
                                    value={category.id}
                                    selected={filterCategory===category.id && "DefaultSelected"}
                                     > 
                                 {category.translations[0].name}
                                 </option>
                                 })
                            }
                        </select>
                        </label>
                            <label htmlFor="articulation">{data.textArticulation}
                        <select className="select" name="articulation" id="articulation" onChange={(event)=>selectJoint(event.target.value)}>
                            <option value=""  selected={filterJoint===null && "DefaultSelected"}>
                                {data.textOption}
                                </option>
                            {
                              member &&
                                member.map((member,id) =>{
                                  return <option key={id}
                                    value={member.id}
                                    selected={filterJoint===member.id && "DefaultSelected"}
                                     > 
                                 {member.translations[0].name}
                                 </option>
                                 })
                            }
                        </select>
                        </label>
                        {/* {
                        (filterCategory) &&
                        <button type="button" 
                        className="closeButton btn "
                        onClick = {handleRestFilterCategory}
                        >

                            {categoryName} &nbsp;
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-x-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.146-3.146a.5.5 0 0 0-.708-.708L8 7.293 4.854 4.146a.5.5 0 1 0-.708.708L7.293 8l-3.147 3.146a.5.5 0 0 0 .708.708L8 8.707l3.146 3.147a.5.5 0 0 0 .708-.708L8.707 8l3.147-3.146z"/>
                            </svg>
                        </button>
                    }
                    {
                        filterJoint &&
                        <button 
                            type="button"
                            className="closeButton btn "
                            onClick = {handleRestFilterJoint}
                            >{jointName} &nbsp;
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-x-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.146-3.146a.5.5 0 0 0-.708-.708L8 7.293 4.854 4.146a.5.5 0 1 0-.708.708L7.293 8l-3.147 3.146a.5.5 0 0 0 .708.708L8 8.707l3.146 3.147a.5.5 0 0 0 .708-.708L8.707 8l3.147-3.146z"/>
                            </svg>
                            </button>
                    } */}

                    
                       
                    </div>

                   <div className='gridCard'>
                  {
                          currentArticles &&
                          currentArticles.map((std) => {
                                return (
                                    
                                        <Scientific  
                                         key={std.id}
                                         title={std.title} 
                                         abstract={std.abstract}
                                          authors={std.authors} 
                                         slug={std.category.slug}
                                         slugMember={std.member.slug}
                                         
                                         full_url={std.file.filename_disk} 
                                         url_img={std.image.data.full_url}
                                         textdownload={data.textdownload} 
                                         readMore={data.readMore} 
                                         readLess={data.readLess}
                                         
                                         filterCategory={selectCategory}
                                         filterJoint={selectJoint}
                                         favoris={study.find(s=>s.title===std.title)}
                                         />
                                    
                                )
                            })
                        }
                          </div> 
                          <Pagination
                                    articlesPerPage={articlesPerPage}
                                    totalArticles={studies.length}
                                    paginate={paginate}
                                    currentPage={currentPage}

                                />
                    </div>
                    
                </SideBar>
              
             <Footer/>  
   
    </>
  )
}

export default ScientificStudies