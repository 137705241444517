import React , { useState, useEffect }from 'react'
import { FormLogin } from '../../Components/FormLogin/FormLogin';
import Meta from "../../Components/Meta";
import Language from "../../Components/Languages"
import * as loginData from "../../Data/Login.json";
import { useParams } from 'react-router-dom';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand
} from 'mdb-react-ui-kit';
import Breadcrumb from '../../Components/Breadcrumb';
const Login= (props) => {
  const {lang} = useParams();
  const [data, setData] = useState(loginData.default[lang]);
console.log(lang);
  const [meta, setMeta] = useState({
      title: loginData.default[lang].meta.title,
      description: loginData.default[lang].meta.description,
      canonical: loginData.default[lang].meta.canonical,
      image: loginData.default[lang].meta.image,
      meta: {
          charset: "utf-8",
          name: {
              keywords: loginData.default[lang].meta.keywords,
          }

      },
  });

  useEffect(() => {
      setData(loginData.default[lang]);
      setMeta({
          title: loginData.default[lang].meta.title,
          description: loginData.default[lang].meta.description,
          canonical: loginData.default[lang].meta.canonical,
          keywords: loginData.default[lang].meta.keywords,
          image: loginData.default[lang].meta.image
      });
  }, [lang]);
  return (
    <>
    
    <Meta meta={meta} /> 
    <header >
      <MDBNavbar light bgColor='white' expand='lg' className='me-2 '>
          <MDBContainer>
            <MDBNavbarBrand href='#'>
              <img
                src="https://mad-up.com/api/uploads/mad-up/originals/traLogoMADAcademy.png"
                height='50'
                alt='Logo MAD-UP Academy'
                loading='lazy'
              />
            </MDBNavbarBrand>
            <div className='d-flex w-auto mb-3'> <Language/></div>
          </MDBContainer>
        </MDBNavbar>
    </header>
   
    <Breadcrumb title={data.breadcrumb} />
    <FormLogin 
      email={data.form.email} password={data.form.password} ButtonText1={data.form.ButtonText1} src={`/${lang}/HomeAcademy`}
     textSpan={data.form.textSpan} SLUG={`/${lang}/${data.form.SLUG}`} 
     TextSLUG={data.form.TextSLUG} 
     SLUGPassword={`/${lang}/${data.form.SLUGPassword}`} 
     errorActif={data.form.errorActif}
     errorPassword={data.form.errorPassword}
     errorMail={data.form.errorMail}
    

    />

    
   
    </>
  )
}

export default Login;