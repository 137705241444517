import React,{useState, useEffect} from 'react';
import { withRouter,Redirect, useParams, Link} from "react-router-dom";
import './AddDevice.scss';
import axios from 'axios';
import * as Yup from 'yup';
import { lazy } from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as dataJSON from "../../Data/MyDevice.json";
import { BiMessageAltError } from "react-icons/bi";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon
} from 'mdb-react-ui-kit'

export const AddDevice = ({date, numSerial, spanRevendeur, 
  text1,title ,labelRevendeur, labelFile,
  ButtonText1, spanFile, pFile, textMail, textOblig, errorDeviceUnknow, inputFile, errorBsn, errorOldSerialNumber, errorNewSerialNumber, errorSerialNumber,numCount, msg, msgE, textError, textError2}) => {
 
    const { lang } = useParams();
    const [status, setStatus] = useState(false);
    const [basicModal, setBasicModal] = useState(false);
    const [data, setData] = useState(dataJSON.default[lang]);
  
    const toggleShow = () => {
      setBasicModal(!basicModal);
      document.getElementById("name").value = "";
      toggleSN();
    };

    const toggleSN = () => {
      document.getElementById("deviceError").style.visibility = "hidden";
      let value1 = document.getElementById("name").value;
      let div1 = document.getElementById("old_serial_num");
      let divOr = document.getElementById("or");
      let div2 = document.getElementById("new_serial_num");
      let div3 = document.getElementById("serial_num");
   
      if (value1 === "PRO") {
          div1.style.visibility = "visible";
          divOr.style.visibility = "visible";
          div2.style.visibility = "visible";
      }
      else {
          div1.style.visibility = "hidden";
          divOr.style.visibility = "hidden";
          div2.style.visibility = "hidden";
      }
   
      if (value1 === "PHYSIO") {
          div3.style.visibility = "visible";
      }
      else {
          div3.style.visibility = "hidden";
      }
  };

    let auth=[];
    auth=JSON.parse(sessionStorage.getItem('userData'))

    const physioSchema = Yup.object().shape({
      serial_num: Yup.string().matches(/^B/,errorBsn)
                          .matches(/^[A-Z]{2}[0-9]{8}/,errorSerialNumber)
                          .length(10, numCount)
                          .required(text1),
      date_purchase: Yup.string().required(text1),
      name_distributer: Yup.string().required(text1)
      });
    
    const oldProSchema = Yup.object().shape({
      old_serial_num: Yup.string().matches(/^MDP19237\/[0-9]/,errorOldSerialNumber),
      date_purchase: Yup.string().required(text1),
      name_distributer: Yup.string().required(text1)
      });

    const newProSchema = Yup.object().shape({
      new_serial_num: Yup.string().matches(/^[A-Z]{2}[0-9]{8}/,errorNewSerialNumber)
                            .length(10, numCount),
      date_purchase: Yup.string().required(text1),
      name_distributer: Yup.string().required(text1)
      });
    
    const validationSchema = lazy(({name = document.getElementById("name").value, old_serial_num}) => name === 'PHYSIO' ? physioSchema : old_serial_num === '' ? newProSchema : oldProSchema);
   
      const initialValues = {  
        serial_num: "",
        name_distributer: "",
        date_purchase:"",
        myFile:"",
        name:"",
        firstName:auth["first_name"],
        lastName:auth["last_name"],
        user_id:auth["user_id"],
        email:auth["email"],
        id_sellsy:auth["id_sellsy"],
        toggleSN
      };

     const hostName="/Back-end/addDevice";
      const handleSubmit = (dataDevice) => {
           axios.post(hostName,dataDevice)
          .then((response)=>{
            console.log(response.data);
              if(response.data.status === false)
              {
                  console.log("error");
                  document.getElementById("deviceError").style.visibility = "visible";
              }
              else
              {
                window.location.reload();
              }
          }).catch(error=>{
            console.log(error)
          })
        
      };
      
      return (
        <>
            <MDBIcon onClick={toggleShow} fas icon="plus-circle"  style={{ color: '#15acf2', fontSize: '3rem', margin:"5px" }}/>
            <div className='modalAdd'>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
        <MDBModalDialog  style={{margin:"200px auto auto",maxWidth:1000}}>
          <MDBModalContent style={{borderRadius:"25px", border:"1px solid #15acf2",paddingLeft:"10px", fontWeight:"bold"}}>
            <MDBModalHeader style={{border:"none", paddingBottom:"0px"}}>
              <MDBModalTitle style={{textTransform:"uppercase" , fontWeight:"bold"}}>{title}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>  <p className='error'></p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(deviceData)=>{
                  document.getElementById("deviceError").style.visibility = "hidden";
                  deviceData.name = document.getElementById("name").value;
                  if(deviceData.name === 'PRO')
                  {
                    console.log(document.getElementById("old_serial_num").value);
                    if(document.getElementById("old_serial_num").value === '')
                    {
                      deviceData.serial_num = document.getElementById("new_serial_num").value;
                    }
                    else
                    {
                      deviceData.serial_num = document.getElementById("old_serial_num").value;
                    }
                  }
                  handleSubmit(deviceData);
                }}
              >
                {({ 
                  handleChange,
                  handleBlur,
                  values,
                  setFieldValue,
                  handleReset
                   }) => (
                  <Form className='formDevice'>
                    <div className="form-group mb-4">
                      <label htmlFor="name" className="me-2">{data.form.labelProdName}</label>
                      <select id="name" name="name" onChange={toggleSN}>
                        <option value="" selected disabled>{data.form.prodNameLabelSelect}</option>
                        <option value="PRO">PRO</option>
                        <option value="PHYSIO">PHYSIO</option>
                      </select>
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="serial_num" className='me-2'>{numSerial}</label>
                        
                          <Field
                            type="text"
                            id="old_serial_num"
                            name="old_serial_num"
                            placeholder="MDP19237/_ _ _ _ _  "
                            visibility="hidden"
                          />
                          <span id="or" className='font'> OU </span>
                          <Field
                            type="text"
                            id="new_serial_num"
                            name="new_serial_num"
                            placeholder="A _ _ _ _ _ _ _ _"
                            visibility="hidden"
                          />
                          <Field
                            type="text"
                            id="serial_num"
                            name="serial_num"
                            placeholder="B _ _ _ _ _ _ _ _"
                            visibility="hidden"
                          />
                        
                        <ErrorMessage
                            name="new_serial_num"
                            component="small"
                            className="text-danger"
                          />
                        <ErrorMessage
                            name="old_serial_num"
                            component="small"
                            className="text-danger"
                          />
                        <ErrorMessage
                            name="serial_num"
                            component="small"
                            className="text-danger"
                          />
                    </div>
                   
                    <div className="form-group mb-4">
                      <label htmlFor="name_distributer">
                     {labelRevendeur}
                      </label>
                       <span className='span'>{spanRevendeur} </span>
                       <span>: </span>
                      <Field
                        type="text"
                        id="name_distributer"
                        name="name_distributer"
                        
                      />
                      <ErrorMessage
                        name="name_distributer"
                        component="small"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group mb-5">
                      <label htmlFor="date_purchase">
                     {date} &nbsp; </label>
                      <Field
                        type="date"
                        id="date_purchase"
                        name="date_purchase"
                        placeholder={date}
                        
                      />
                      <ErrorMessage
                        name="date_purchase"
                        component="small"
                        className="text-danger"
                      />
                    </div>                    
                    <MDBModalFooter className="d-flex justify-content-between align-items-start" style={{border:"none", paddingTop:"0px"}}>
              <div>
              <span className='span'>{textMail}</span>
              <div className='textOblig'>{textOblig}</div>
              <label id="deviceError" name="deviceError" className="text-danger">{errorDeviceUnknow}</label>
              </div>
             
             <button type="submit" style={{background:"#15acf2", border:"none", width:"150px", color:"#fff", padding:"10px", borderRadius:"10px", fontWeight:"bold"}}>{ButtonText1}</button>    
             
             
            </MDBModalFooter>
            <p className='msg'></p>
                  </Form>
                )}
              </Formik>
              </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      </div>      
          </>
      )
    };

export default AddDevice;
