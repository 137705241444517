import React from 'react';
import ReactDOM from 'react-dom';
import { Formik, Field, Form } from 'formik';
import axios from 'axios';


const FAQQuestion = ({textPlaceholder, questionRe}) => {
    let auth=[];
    auth=JSON.parse(sessionStorage.getItem('userData'))
    const initialValues = {  
        email: auth["email"],
        question: "",
        firstName:auth["first_name"],
        lastName:auth["last_name"]
      };
   const hostName="/Back-end/FAQQuestion";
  // const hostName="http://localhost/Back-end/FAQQuestion"
   const handleSubmit = (values) => {
        axios.post(hostName,values)
       .then((response)=>{
        if (response.status===200){
            document.querySelector(".question").innerHTML=questionRe;
            window.location.reload()
        }
         
       }).catch(error=>{
         console.log(error)
       })
     
   };
    return(
        <div>
            <p className='question'></p>
        <Formik
          initialValues={initialValues}
          onSubmit={ (values) => {
            handleSubmit(values)
          }}
        >
          <Form>
          
          <div className="buttonIn">
            <Field
              id="question"
              name="question"
              placeholder={textPlaceholder}
              as='textarea'
              rows="1" cols="20"
              className="inputSize"
            />
            <button type="submit" className='buttonSubmit'><i className="fa-solid fa-arrow-right"></i></button>
            </div>
          </Form>
        </Formik>
      </div>
    )
}
export default FAQQuestion;

