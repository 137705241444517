import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import * as dataJSON from "../../Data/NavBarAccount.json";
import "./Navbar.scss";

import styled from "styled-components";
import Language from "../Languages";
import LogoMadUPAcademy from "../LogoMadUPAcademy/LogoMadUPAcademy";
const NavBarItem = (props) => {
  const { lang } = useParams();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const [data, setData] = useState(dataJSON.default[lang]);

  useEffect(() => {
    setData(dataJSON.default[lang]);
  }, [lang]);

  let activeStyle = {
    backgroundColor: "#15acf2",
    color: "white",
    padding:"15px 8px"
  };
  const authUser=JSON.parse(sessionStorage.getItem('userData'));
  return (
    <>
      {/* <LogoMadUPAcademy/> */}
      <div className="NavBar ">
        <nav className="Navigation">
           
          <ul>
            {data.menu.map((item, id) => {
              const { SLUG, text } = item;
              // if (id !== 0) {
                  return (
                    <li key={id}>
                      <NavLink
                        to={`/${lang}${SLUG}`}
                        exact="true"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        onClick={() => {
                          scrollToTop();
                        }}
                      >
                        <span>{text}</span>
                      </NavLink>
                    </li>
                  );
             
              // } else {
              //     return (
              //         <li className='dropdownMenu' key={id}>
              //             {/* <NavLink
              //                 to=""

              //                 exact activeStyle={{
              //                     backgroundColor: '#15acf2',
              //                     color: "#fff"
              //                   }}
              //                 // key={id}
              //                 onClick={() => {
              //                     scrollToTop();
              //                 }}
              //             > */}
              //                 <span className="spanDropown">{text}</span>
              //             {/* </NavLink> */}

              //             <ul className="dropdownMenu-content" key={id}>
              //                 {data.menuSciences.map((item, id) => {

              //                     const { SLUG, text } = item;
              //                     return (
              //                         <li key={id}>
              //                             <NavLink
              //                               className="a"
              //                             exact='true'
              //                             style={({ isActive }) =>
              //                             isActive ? activeStyle : undefined
              //                             }
              //                                 to={`/${lang}${SLUG}`}

              //                                 key={id}
              //                                 onClick={() => {
              //                                     scrollToTop();
              //                                 }}
              //                             >
              //                                 {text}
              //                             </NavLink>

              //                         </li>
              //                     );

              //                 })}
              //             </ul>
              //         </li>)

              // }
            })}
          
          </ul>
          </nav>
        </div>
    </>
  );
};

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  flex-direction: start;
  li {
    padding: 18px 10px;
  }
  @media (max-width: 1100px) {
    flex-flow: column nowrap;
    background-color: #0d2538;
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 74px;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;
    }
  }
`;
export default NavBarItem;
