import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from "react-router-dom";
import Breadcrumb from '../../Components/Breadcrumb';
import * as dataJSON from "../../Data/UserProfile.json";
import Meta from "../../Components/Meta";
import axios from 'axios';
import "./Profile.scss";
import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount";
import SideBar from '../../Components/SideBar/SideBar';
import Footer from '../../Components/Footer'
import UserProfile from '../../Components/UserProfile/UserProfile';
import GetInterest from '../../Components/GetInterest/GetInterest';
export const Profile = (props) => {

  const {lang} = useParams();
  const [data, setData] = useState(dataJSON.default[lang]);
  const [meta, setMeta] = useState({
    title: dataJSON.default[lang].meta.title,
    description: dataJSON.default[lang].meta.description,
    canonical: dataJSON.default[lang].meta.canonical,
    image: dataJSON.default[lang].meta.image,
    meta: {
      charset: "utf-8",
      name: {
        keywords: dataJSON.default[lang].meta.keywords,
      }

    },
  });
 
  const[auth,setAuth]=useState([]);
  useEffect(()=>{
      
      const authUser=JSON.parse(sessionStorage.getItem('userData'));
      setAuth(authUser);
  },[])

  useEffect(() => {
    setData(dataJSON.default[lang]);
    setMeta({
      title: dataJSON.default[lang].meta.title,
      description: dataJSON.default[lang].meta.description,
      canonical: dataJSON.default[lang].meta.canonical,
      keywords: dataJSON.default[lang].meta.keywords,
      image: dataJSON.default[lang].meta.image
    });
  }, [lang]);



  
  if(auth===null){
    return <Navigate to={`/${lang}/connexion`} />;
  }



  return (
<>
    <div className='formCreate'>
      <Meta meta={meta}/>
      <HeaderAccount />
      <Breadcrumb title={data.breadcrumb} />
      
     
      {/* <div>
      
      <i className="fa-solid fa-user icon"></i>
      <span className='spanAuth'>{auth['first_name']}&nbsp;{auth['last_name']}</span>
      </div> */}
      
     
      
        <SideBar>
        <section className='sectionProfile'>
          <UserProfile 
   
          {...data.form}
          />
        
          <GetInterest
         {...data.interest}

        />
        
            
        
      
      </section>

    </SideBar>
         
    </div>  
    <Footer/>      
     </>
  )
};

export default Profile