import React from "react";
import "./BigTitle.scss";

export default ({ text, title, span, direct, small }) => {
    const Small = () => {
        if (small !== undefined) {
            return <span className="big-title_small">{small}</span>;
        } else {
            return false;
        }
    };

    return (
        <div className="big-title">
            <div className="container">
                <div className={direct}>
                    <div className="big_text">{text}</div>
                    <h2>
                        {title} <span>{span}</span>
                        <Small />
                    </h2>
                </div>
            </div>
        </div>
    );
};
