import React , { useState, useEffect , useRef}from 'react'
import { Navigate, useParams } from "react-router-dom";
import BigTitle from "../../Components/BigTitle";
import Meta from "../../Components/Meta";
import * as dataMedia from "../../Data/MediaLibrary.json";
import "./MediaLibrary.scss";
import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount"
import Breadcrumb from '../../Components/Breadcrumb';
import SideBar from '../../Components/SideBar/SideBar';
import Footer from '../../Components/Footer';
import axios from 'axios';
import "./MediaLibraryCard"
import Pagination from '../../Components/Pagination/Pagination';
import MediaLibraryCard from './MediaLibraryCard';
 const MediaLibrary= (props) => {
  const {lang} = useParams ();
  const [data, setData] = useState(dataMedia.default[lang]);
  const[media, setMedia]=useState([]);
  const[category, setCategory]=useState([]);
  const[filterCategory,setFilterCategory]=useState(null)
  const[mediaType,setMediaType]=useState(null)
  const[filterMedia,setfilterMedia]=useState(null)

  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(10);

  const prevCategoryRef = useRef();
  const prevJointRef = useRef();

  const [meta, setMeta] = useState({
      title: dataMedia.default[lang].meta.title,
      description: dataMedia.default[lang].meta.description,
      canonical: dataMedia.default[lang].meta.canonical,
      image: dataMedia.default[lang].meta.image,
      meta: {
          charset: "utf-8",
          name: {
              keywords: dataMedia.default[lang].meta.keywords,
          }

      },
  });

  useEffect(() => {
      setData(dataMedia.default[lang]);
      setMeta({
          title: dataMedia.default[lang].meta.title,
          description: dataMedia.default[lang].meta.description,
          canonical: dataMedia.default[lang].meta.canonical,
          keywords: dataMedia.default[lang].meta.keywords,
          image: dataMedia.default[lang].meta.image
      });
  }, [lang]);


  useEffect(()=>{
    
    axios.get(`
        https://mad-up.com/api/mad-up/items/category_filter?fields=id,sort,slug,translations.name,translations.language&lang=${lang}`)
    .then(reponse => {
        setCategory(reponse.data.data);
        //console.log(reponse.data.data)
        
      
    })
    
  },[lang])

      
    const authUser=JSON.parse(sessionStorage.getItem('userData'));
    

  useEffect(()=>{
  
    axios.get(`
        https://mad-up.com/api/mad-up/items/media_type?fields=id,translations.name,translations.language&lang=${lang}`)
    .then(reponse => {
        setMediaType(reponse.data.data);
        //console.log(reponse.data.data)
    })  
  },[lang])

  const loadData=()=>{
    const category=filterCategory ?`filter[category]=${filterCategory}`:""
    const mediaType=filterMedia? `filter[media_type]=${filterMedia}`:""
    const role=`filter[role.role_id.role]=${authUser.role}`
    const url=`https://mad-up.com/api/mad-up/items/mdiathque_partenaire?fields=id,media.*,media_type.id,category.id&${category}&${mediaType},role.role_id.role&${role}`
   console.log(url)
    axios.get(url)
    .then(reponse => {
        setMedia(reponse.data.data);
        console.log(reponse.data.data)
    })
  }
  useEffect(()=>{
    prevCategoryRef.current = filterCategory;
    prevJointRef.current=filterMedia;
    loadData()
    //console.log(filterCategory)

},[filterCategory, filterMedia])

const selectCategory=(idCategory)=>{
    // console.log(`demande de la catégorie ${idCategory}`)
     setFilterCategory(idCategory)
     
     //loadData()
          
}
//console.log(filterCategory)

const selectJoint=(idJoint)=>{
 //console.log(`demande de type de media ${idJoint}`)
 setfilterMedia(idJoint)
 
}
 


  if((authUser===null)){
    return <Navigate to={`/${lang}/connexion`} />;
  }
// connaitre la page courante
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = media.slice(indexOfFirstArticle, indexOfLastArticle);

// changement de page
const paginate = pageNumber => setCurrentPage(pageNumber);
  return (
    <>
     
    <Meta meta={meta} />
   <HeaderAccount />
      <Breadcrumb title={data.breadcrumb} />

                <SideBar>
                <div >
                    <div className='filter'>
                       <label htmlFor="categorie">{data.textCategory}
                        <select className="select" name="category" id="category" onChange={(event)=>selectCategory(event.target.value)}>
                            <option value="" selected={filterCategory===null && "DefaultSelected"}>
                                {data.textOption}
                            </option>
                            {
                              category &&
                                category.map((category,id) =>{
                                    return <option key={id}
                                    value={category.id}
                                    selected={filterCategory===category.id && "DefaultSelected"}
                                     > 
                                 {category.translations[0].name}
                                 </option>
                                 })
                            }
                        </select>
                        </label>
                            <label htmlFor="mediaType">{data.textMediaType}
                        <select className="select" name="mediaType" id="mediaType" onChange={(event)=>selectJoint(event.target.value)}>
                            <option value=""  selected={filterMedia===null && "DefaultSelected"}>
                                {data.textOption}
                                </option>
                            {
                              mediaType &&
                                mediaType.map((media,id) =>{
                                  return <option key={id}
                                    value={media.id}
                                    selected={filterMedia===media.id && "DefaultSelected"}
                                     > 
                                 {media.translations[0].name}
                                 </option>
                                 })
                            }
                        </select>
                        </label>
                   
                    </div>
                    <div className='gridCard'>
                  {
                          currentArticles &&
                          currentArticles.map((md) => {
                                return (
                                    
                                        <div key={md.id}>
                                            <MediaLibraryCard
                                            filename_disk={md.media.filename_disk} 
                                            url={md.media.filename_disk}
                                            />

                                        </div>
                                    
                                )
                            })
                        }
                          </div> 
                          <Pagination
                                    articlesPerPage={articlesPerPage}
                                    totalArticles={media.length}
                                    paginate={paginate}
                                    currentPage={currentPage}

                                />
                    </div>
                    
               
                </SideBar>
               
   <Footer/>
    </>
  )
}

export default  MediaLibrary;