import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard({title, created_on, content_short, full_url, content_full}) {
  const [expanded, setExpanded] = React.useState(false);
  const[liked, setLiked]=React.useState(false);

  const handelLiked=()=>{
    setLiked(true)
  }
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const convertDate=(inputFormat)=> {
    function pad(s) {
        return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [
        pad(d.getDate()),
        pad(d.getMonth() + 1),
        d.getFullYear(),
    ].join("/");
}
  return (
    <Card sx={{ maxWidth: 500 }} style={{margin:"auto"}}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "#15acf2" }} aria-label="recipe">
           DA
          </Avatar>
        }
        title={title}
        subheader={convertDate(created_on)}
      />
      <CardMedia
        component="img"
        height="300"
        image={full_url}
        alt={full_url}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
        {content_short}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
     
      {/* {liked ? (<IconButton aria-label="add to favorites"><FavoriteIcon  color="error"/></IconButton>): ( <IconButton aria-label="add to favorites" onClick={handelLiked}><FavoriteIcon /> </IconButton>) }  */}
       
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph  dangerouslySetInnerHTML={{ __html:content_full }}>
           
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
