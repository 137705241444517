import React, { useState, useEffect } from "react";
import IconButton  from "@mui/material/IconButton";
const Card = ({ description,
    title,
    full_url,
    readMore,
    readLess,  
    url_img}) => {
    
    const [show, setShow] = useState(false);
    
    return (
  <div className="card borderCard">
            <div className="card-header headerFlex" style={{height:"8rem"}}>
                <img src={url_img} alt="img"/>
                <span className="titleHeader">{title}</span>
              </div>
              <div className="card-body" >
                {description != "" ? (
                  <div dangerouslySetInnerHTML={{
                    __html: show ? description : `${description.slice(0, 200)}...`,
                  }}></div>
                ) : (
                  <div></div>
                )}
                <span className="buttonRead color" onClick={() => setShow(!show)}>
                  <span className="hyphen"></span>
                  <span className="buttonRead_text">
                    {" "}
                    {!show ? readMore : readLess}
                  </span>
                </span>
              </div>
              <div className="card-footer flex">
                <a
                  className="center"
                  href={`https://mad-up.com/api/uploads/mad-up/originals/${full_url}?download`}
                  target="_blank"
                  download="Your File.pdf"
                >
                  <IconButton aria-label="download">
                    <i className="fa-solid fa-download"></i>
                  </IconButton>
                </a>
              </div>
  </div>
    );
  };
  export default Card;