import React , { useState, useEffect, useRef }from 'react'
import { Navigate, useParams, Link } from "react-router-dom";
import Meta from "../../Components/Meta";
import * as dataElearning from "../../Data/Elearning.json";
import "./Elearning.scss";
import axios from "axios";
import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount"
import Breadcrumb from '../../Components/Breadcrumb';
import SideBar from '../../Components/SideBar/SideBar';
import Footer from '../../Components/Footer';
import { MdOndemandVideo } from "react-icons/md";
 const ElearningPageDetail= (props) => {
  const {lang} = useParams ();
  const {slug}= useParams();  
  const [data, setData] = useState(dataElearning.default[lang]);
  const[video, setVideo]=useState([]);
  const[elearning,setElearning]=useState([])

  const [meta, setMeta] = useState({
      title: dataElearning.default[lang].meta.title,
      description: dataElearning.default[lang].meta.description,
      canonical: dataElearning.default[lang].meta.canonical,
      image: dataElearning.default[lang].meta.image,
      meta: {
          charset: "utf-8",
          name: {
              keywords: dataElearning.default[lang].meta.keywords,
          }

      },
  });

  useEffect(() => {
      setData(dataElearning.default[lang]);
      setMeta({
          title: dataElearning.default[lang].meta.title,
          description: dataElearning.default[lang].meta.description,
          canonical: dataElearning.default[lang].meta.canonical,
          keywords: dataElearning.default[lang].meta.keywords,
          image: dataElearning.default[lang].meta.image
      });
  }, [lang]);

  useEffect(()=>{
    axios.get(`https://mad-up.com/api/mad-up/items/elearning?fields=id,category_title.*,duration_video,category_title.id,translations.*,translations.video.*,translations.title,translations.language&lang=${lang},category_title.slug&filter[category_title.slug][eq]=${slug}`
   )
.then(reponse => {
    setVideo(reponse.data.data);
    console.log(reponse.data.data)
    
  
})
  }
,[lang])



const hostName="/Back-end/getElearning";
//const hostName="http://localhost/Back-end/getElearning"
useEffect(() => {
 getElerningById();
}, []);
const getElerningById=()=>{
axios
.get(
  `${hostName}/${auth["user_id"]}`
 
)
.then((reponse) => {
 console.log(reponse.data)

  setElearning(reponse.data)
  
});
}




  const auth=JSON.parse(sessionStorage.getItem('userData'));
  if(auth===null){
    return <Navigate to={`/${lang}/connexion`} />;
  }
  
  return (
    <>
     
    <Meta meta={meta} />
   <HeaderAccount />
      <Breadcrumb title={data.breadcrumb} />
        <SideBar>
          
        <div className='elearningDetail'>
            <h2 className='textProg'>{data.textProg}</h2>  
            <div className='desc'>
            <p>Lorem ipsum dolor sit amet</p>
              <ul>              
               {video&& video.map(v=>{
                return (
                  
                    <li key={v.id}>
                       
                    <Link to={`/${lang}/E-learning/${v.category_title.slug}/${v.id}`}>
                    {
                      elearning.map(e=>{
                       
                          if(e.idV==v.id && e.statusV==1){
                            return (
                              <i key={e.id} className="fa-solid fa-circle-check"></i>
                            )
                          }
                        
                      })
                    }
                      <MdOndemandVideo/>
                    <span>&ensp;{v.translations[0].title} <span>({v.duration_video})</span></span>

                    <button>{data.btn}</button>
                    
                   </Link>
                   
                   </li>
               
                )
               })}
                 </ul>
                 
               
                 <div className='buttonNavigation'>
            
            
            <Link to={`/${lang}/E-learning`} className="link">{data.textPrevious}</Link>
            {/* <Link to="" className='link'>{data.textNext}</Link> */}
          </div>
            </div>

           
       </div>
      
        </SideBar>
               
   <Footer/>
    </>
  )
}

export default  ElearningPageDetail;