import React , { useState, useEffect,useRef }from 'react'
import { Navigate, useParams } from "react-router-dom";
//import BigTitle from "../../Components/BigTitle";
import Meta from "../../Components/Meta";
import * as dataJson from "../../Data/clinicalCase.json";
import "./ClinicalsCase.scss";
import axios from 'axios';

import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount"
import SideBar from '../../Components/SideBar/SideBar';
import Breadcrumb from '../../Components/Breadcrumb';
import Pagination from '../../Components/Pagination/Pagination';
import ClinicalCase from '../../Components/clinicalCase/clinicalCase';
import Footer from '../../Components/Footer'
 const ClinicalsCase= (props) => {
  const {lang} = useParams();
  const [data, setData] = useState(dataJson.default[lang]);
  const[ClinicalsCase, setClinicalsCase]=useState([]);
  const[category, setCategory]=useState([]);
  const[filterCategory,setFilterCategory]=useState(null)
  const[member,setMember]=useState(null)
  const[filterJoint,setFilterJoint]=useState(null)

  const [study, setStudy]=useState([]);
  const prevCategoryRef = useRef();
  const prevJointRef = useRef();

  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(12);

  const [meta, setMeta] = useState({
      title: dataJson.default[lang].meta.title,
      description: dataJson.default[lang].meta.description,
      canonical: dataJson.default[lang].meta.canonical,
      image: dataJson.default[lang].meta.image,
      meta: {
          charset: "utf-8",
          name: {
              keywords: dataJson.default[lang].meta.keywords,
          }

      },
  });

  useEffect(() => {
      setData(dataJson.default[lang]);
      setMeta({
          title: dataJson.default[lang].meta.title,
          description: dataJson.default[lang].meta.description,
          canonical: dataJson.default[lang].meta.canonical,
          keywords: dataJson.default[lang].meta.keywords,
          image: dataJson.default[lang].meta.image
      });
  }, [lang]);


  const loadData=()=>{
    const category=filterCategory ?`filter[pathologie]=${filterCategory}`:""
   
    const url=`https://mad-up.com/api/mad-up/items/clinical_case?fields=id,file.*,image.*,title,abstract,pathologie.id,pathologie.slug&${category}`
   // console.log(url)
    axios.get(url)
    .then(reponse => {
        setClinicalsCase(reponse.data.data);
       // console.log(reponse.data.data)
    })
  }
  useEffect(()=>{
    prevCategoryRef.current = filterCategory;
    prevJointRef.current=filterJoint;
    loadData()
    //console.log(filterCategory)

},[filterCategory, filterJoint])

  useEffect(()=>{
    
    axios.get(`
        https://mad-up.com/api/mad-up/items/pathologie_filter?fields=id,slug,translations.name,translations.language&lang=${lang}`)
    .then(reponse => {
        setCategory(reponse.data.data);
        //console.log(reponse.data.data)
        
      
    })
    
  },[lang])
 

    

  
const selectCategory=(idCategory)=>{
       // console.log(`demande de la catégorie ${idCategory}`)
        setFilterCategory(idCategory)
        
        //loadData()
             
}

  const hostName1="/Back-end/getFavoris";
 //const hostName1="http://localhost/Back-end/getFavoris"

  const auth=JSON.parse(sessionStorage.getItem('userData'));
  console.log(auth)
const getFavoris=()=>{
  
  axios
  .get(
    `${hostName1}/${auth["user_id"]}`
   
  )
  .then((reponse) => {
    setStudy(reponse.data)
    
  });
}
useEffect(() => {
  getFavoris();
  }, []);



  if(auth===null){
    return <Navigate to={`/${lang}/connexion`} />;
  }
    //connaitre la page courante
    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = ClinicalsCase.slice(indexOfFirstArticle, indexOfLastArticle);
  
  // changement de page
  const paginate = pageNumber => setCurrentPage(pageNumber);
  return (
    <>
   
    <Meta meta={meta} />
    <HeaderAccount />
    <Breadcrumb title={data.breadcrumb} />
                <SideBar>
                <div>
                <div className='filter'>
                        <label htmlFor="categorie">{data.textCategory}
                        <select className="select" name="category" id="category" onChange={(event)=>selectCategory(event.target.value)}>
                            <option value="" selected={filterCategory===null && "DefaultSelected"}>
                           
                             {data.textOption}
                            </option>
                            {
                              category &&
                                category.map((category,id) =>{
                                    return <option key={id}
                                    value={category.id}
                                    selected={filterCategory===category.id && "DefaultSelected"}
                                     > 
                                 {category.translations[0].name}
                                 </option>
                                 })
                            }
                        </select>
                        </label>       
                    </div>
                    <div className='gridCard' >
                        {
                          currentArticles &&
                          currentArticles.map((clic) => {
                                return (
                                    
                                        <ClinicalCase 
                                        key={clic.id} 
                                        url_img={clic.image.data.full_url}
                                        
                                         idCategory={clic.pathologie.id}
                                         filterCategory={selectCategory}
                                        title={clic.title}
                                         abstract={clic.abstract} 
                                         full_url={clic.file.filename_disk}  
                                         textdownload={data.textdownload} 
                                         readMore={data.readMore} 
                                         readLess={data.readLess}
                                         favoris={study.find(s=>s.title===clic.title)}
                                         />
                                    
                                )
                            })
                        }
                    </div>
                    
                    <Pagination
                                    articlesPerPage={articlesPerPage}
                                    totalArticles={ClinicalsCase.length}
                                    paginate={paginate}
                                    currentPage={currentPage}

                     />
                
                </div>
                </SideBar>
                
                <Footer/>
   
    </>
  )
}

export default ClinicalsCase