import React, { useState, useEffect } from "react";
import "./Footer.scss";
import { Link, useParams } from "react-router-dom";

import { FaMapMarkerAlt } from "react-icons/fa";
import * as dataJSON from "../../Data/Footer.json";
import { AiFillPhone } from "react-icons/ai";
import Feedback from "./Feedback";
import { FaEnvelope } from "react-icons/fa";
//import ButtonDemo from "../ButtonDemo"
const Footer = (props) => {
    const scrollToTop = () => window.scrollTo(0, 0);
    let {lang} = useParams();

    const [data, setData] = useState(dataJSON.default[lang]);

    useEffect(() => {
        setData(dataJSON.default[lang]);
    }, [lang]);

    return (
        <>
            <footer>
                <div className="container">
                    <div className="grid">
                        <div>
                            <img
                                src="https://mad-up.com/api/uploads/mad-up/originals/traLogoMADAcademy.png"
                                alt="mad-up logo"
                                className="logo"
                                
                            /> 
                            <p>{data.text}</p> 
                        </div>
                         <div>
                            {data.contact.link.map((item, id) => {
                                    return (
                                         
                                            <h4 key={id}>{item.text}</h4> 
                                        
                                    );
                                })}
                           
                            <address className="contact">
                                
                           <a href="mailto:contact@mad-up.com" > <p className="hover"><FaEnvelope/>  {data.contact.email}</p></a>
                               <a href="https://api.whatsapp.com/send?phone=+330%206%2019%2089%2002%2031"><p className="hover"><AiFillPhone />  {data.contact.num}</p> </a>  
                               <p ><FaMapMarkerAlt/>  {data.contact.address}</p>
                               <p className="ms-4"> {data.contact.ville}</p>
                            </address>
                           
                            
                        </div>
                        <div>
                        {data.map.link.map((item, id) => {
                                    return (
                                         <Link
                                        to={`/${lang}${item.SLUG}`}
                                            key={id}
                                            onClick={() => {
                                                scrollToTop();
                                            }}
                                        >
                                            <h4 className="hover">{item.text} </h4>
                                        </Link>
                                  
                                    );
                                })}
                                
                           
                        </div>
                        <div>
                            <h4>{data.return}</h4>
                            <Feedback textPlaceholder={data.textPlaceholder} reponse={data.reponse} send={data.send}/>
                        </div>
                    </div>
                    
                </div>
            </footer>
           
          
           
        </>
    );
};

export default (Footer);
