import React from 'react';
import { Link } from 'react-router-dom';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "./BlogAccount.scss"
const  CircularProgress= (props) => {
    return(
        <>
    <div  className='progressCircle'>
<CircularProgressbarWithChildren value={props.value}>
  {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
  <img style={{ width: 80, marginTop: -5}} src="https://mad-up.com/api/uploads/mad-up/originals/symbole.png" alt="logo" />
  
 
</CircularProgressbarWithChildren>
<p><i className="fa-solid fa-square"></i> E-LEARNING:  <strong>{props.value} %</strong> </p>
</div>

 <Link to={`/${props.lang}/E-learning`}>{props.textProgress} 
                  </Link>
</>
)}
export default CircularProgress;