import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useParams, Link} from "react-router-dom";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';

import * as dataJSON from "../../Data/Devices.json";

import { Formik, Field, Form } from 'formik';


import "./Devices.scss"
const  DevicesList= (props) => {
  const { lang } = useParams();
  const [data, setData] = useState(dataJSON.default[lang]);

  let auth=[];
  auth=JSON.parse(sessionStorage.getItem('userData'));

  const[deviceList, setDevicesList]=useState([]);
  const hostName="/Back-end/getAvailableDevices";

useEffect(() => {
  axios
    .get(
      `${hostName}`)
    .then((reponse) => {
      setDevicesList(reponse.data);
     console.log(reponse.data)
    });
}, []);

return(
    <>
    <table>
      <tr className='info'>
        <th>{data.tab.sn}</th>
        <th>{data.tab.name}</th>
        <th>{data.tab.code}</th>
        <th>{data.tab.action}</th>
      </tr>
      {
        deviceList.map(device=>{
          return (
            <Device 
              key={device.dev_id}
              close={data.device.close}
              msg={data.device.msg} reponse={data.device.reponse}
              prod_name={device.name}
              name={device.name} dev_id={device.device_id} serial_num={device.serial_num} 
              code={device.code}
            />
          )
        }) 
      }

      <Link to={`/${props.lang}/Devices`}>{props.textDevice} </Link>
    </table>        
  </>
)};

const Device=({reponse, close, msg, name, dev_id, serial_num, code})=>{
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
  const hostName="/Back-end/deleteDevice";
  const handleSubmit = () => {
    axios.post(`${hostName}/${dev_id}`).then((response)=>{
      window.location.reload(false)
    }).catch(error=>{
      console.log(error)
    })
  };

  return(
    <tr className='info'>
      <th className='pinfo'>{serial_num}</th>
      <th className='pinfo'>{name}</th>
      <th className='pinfo'>{code}</th>
      <th className='pinfo'> <i className="fa-regular fa-trash-can"  onClick={toggleShow}></i></th>
  
  
<MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
 
  <MDBModalDialog>
   
    <MDBModalContent>
      <MDBModalHeader style={{border:"none"}}>
        <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
      </MDBModalHeader>
      <Formik
            initialValues={{
              value: "oui",
              id:dev_id,
              name:name,
              serial_num:serial_num,
            }}
            onSubmit={(values) => {
              
              handleSubmit(values);
            }}
          >
        {({ values }) => (
           <Form>
      <MDBModalBody style={{textAlign:"center"}}>{msg}</MDBModalBody>

      <MDBModalFooter style={{border:"none"}}>
        <button type="button" style={{background:"#DC3535", border:"none", width:"100px", color:"#fff", padding:"8px", borderRadius:"10px", fontWeight:"bold", textAlign:"center"}} onClick={toggleShow}>
         {close}
        </button>
        <button style={{background:"#15acf2", border:"none", width:"100px", color:"#fff", padding:"8px", borderRadius:"10px", fontWeight:"bold"}} type="submit" >{reponse}</button>
        <p className='msgRepon'></p>
      </MDBModalFooter>
      
      </Form>
        )}
        </Formik>
    </MDBModalContent>
  </MDBModalDialog>
</MDBModal>
</tr>
  )
}

export default DevicesList;