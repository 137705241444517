import React,{useState, useEffect} from 'react';
import { withRouter,Redirect, Link} from "react-router-dom";
import './GetDevice.scss';
import axios from 'axios';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import ReactLoading from 'react-loading';
import { Formik, Field, Form } from 'formik';

export const GetDevice = ({prodName, numSerial, dateD, reponse, close, msg, codeAct, request}) => {
 
  const[device, setDevice]=useState(null);
  
  const [basicModal, setBasicModal] = useState(false);

  const toggleShow = () => setBasicModal(!basicModal);


  let auth=[];
  auth=JSON.parse(sessionStorage.getItem('userData'))
   const user_id=auth.user_id;
const hostName="/Back-end/getDevice";
  useEffect(() => {
  
    axios
      .get(
        `${hostName}/${user_id}`
       
      )
      .then((reponse) => {
        setDevice(reponse.data);
      });
  }, []);

      return (
    
        <>

         
          {(device)? 
              device.map((device) => {
                return (
                      <Device 
                      key={device.dev_id}
                      prod_name={prodName}
                      numSerial={numSerial} dateD={dateD}
                       reponse={reponse}
                       codeAct={codeAct}
                       close={close}
                        msg={msg} name={device.name} dev_id={device.dev_id} serial_num={device.serial_num} 
                        code={device.code}
                        date_purchase={device.date_purchase}
                        lastName={auth.last_name} 
                        firstName={auth.first_name}
                       />
           
                )
              })
              : <ReactLoading type="bars" color="#15acf2" height={667} width={375} /> 
            }
          </>
    
      )
    };

    const Device=({numSerial, dateD, reponse, close, msg, name, codeAct, code, dev_id, serial_num, request,date_purchase, firstName, lastName})=>{
      const [basicModal, setBasicModal] = useState(false);

      const toggleShow = () => setBasicModal(!basicModal);
       
 const hostName="/Back-end/RequestdeleteDevice";
  const handleSubmit = (dataDevice) => {
      
    axios.post(hostName,dataDevice)
    
   .then((response)=>{
    window.location.reload(false)
    console.log(response.data.successSend)
   }).catch(error=>{
     console.log(error)
   })
 
};

      return(
        <section className='displayDevice'>
                  
        <img src="https://mad-up.com/api/uploads/mad-up/originals/imgDispo.png" alt="Device" />
        
      <div className='info' >
        <p className='pinfo'>{name}</p>
        <hr/>
        <p  className='pinfo'>{numSerial} {serial_num}</p>
        <hr/>
        <p  className='pinfo'>{dateD} {date_purchase}</p>
        <hr/>
        <p  className='pinfo'>{codeAct} {code}</p>
        <hr/>
      </div>
      <i className="fa-regular fa-trash-can"  onClick={toggleShow}></i>
      
    <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
     
      <MDBModalDialog>
       
        <MDBModalContent>
          <MDBModalHeader style={{border:"none"}}>
            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
          </MDBModalHeader>
          <Formik
                initialValues={{
                  value: "oui",
                  id:dev_id,
                  name:name,
                  code:code,
                  serial_num:serial_num,
                  lastName:lastName,
                  firstName:firstName
                }}
                onSubmit={(values) => {
                  
                  handleSubmit(values);
                }}
              >
            {({ values }) => (
               <Form>
          <MDBModalBody style={{textAlign:"center"}}>{msg}</MDBModalBody>

          <MDBModalFooter style={{border:"none"}}>
            <button type="button" style={{background:"#DC3535", border:"none", width:"100px", color:"#fff", padding:"8px", borderRadius:"10px", fontWeight:"bold", textAlign:"center"}} onClick={toggleShow}>
             {close}
            </button>
            <button style={{background:"#15acf2", border:"none", width:"100px", color:"#fff", padding:"8px", borderRadius:"10px", fontWeight:"bold"}} type="submit" >{reponse}</button>
            <p className='msgRepon'></p>
          </MDBModalFooter>
          
          </Form>
            )}
            </Formik>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  </section>
      )
    }

export default GetDevice;
