import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import BigTitle from "../../Components/BigTitle";
import Meta from "../../Components/Meta";
import * as dataSupport from "../../Data/TechnicalSupport.json";
import "./TechnicalSupport.scss";
import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount";
import Breadcrumb from "../../Components/Breadcrumb";
import SideBar from "../../Components/SideBar/SideBar";
import axios from "axios";
import Footer from "../../Components/Footer";
import Card from "./Card";
import Pagination from "../../Components/Pagination/Pagination"
const TechnicalSupport = (props) => {
  const { lang } = useParams();
  const [data, setData] = useState(dataSupport.default[lang]);
  const [manuel, setManuel] = useState([]);
  const[inter,setInter]=useState([]);
  const[metier,setMetier]=useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(12);
  const [currentPageInter, setCurrentPageInter] = useState(1);
  const [articlesPerPageInter] = useState(12);
  const [currentPageMet, setCurrentPageMet] = useState(1);
  const [articlesPerPageMet] = useState(12);
  const [meta, setMeta] = useState({
    title: dataSupport.default[lang].meta.title,
    description: dataSupport.default[lang].meta.description,
    canonical: dataSupport.default[lang].meta.canonical,
    image: dataSupport.default[lang].meta.image,
    meta: {
      charset: "utf-8",
      name: {
        keywords: dataSupport.default[lang].meta.keywords,
      },
    },
  });

  useEffect(() => {
    setData(dataSupport.default[lang]);
    setMeta({
      title: dataSupport.default[lang].meta.title,
      description: dataSupport.default[lang].meta.description,
      canonical: dataSupport.default[lang].meta.canonical,
      keywords: dataSupport.default[lang].meta.keywords,
      image: dataSupport.default[lang].meta.image,
    });
  }, [lang]);

  useEffect(() => {
    axios
      .get(
        `https://mad-up.com/api/mad-up/items/user_manual?fields=id,file.*,image.*,translations.*&lang=${lang}`
      )
      .then((reponse) => {
       // console.log(reponse);
        setManuel(reponse.data.data);
        //console.log(reponse.data.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://mad-up.com/api/mad-up/items/interface?fields=id,image.*,translations.*,translations.file.*,translations.language&lang=${lang}`
      )
      .then((reponse) => {
       // console.log(reponse);
        setInter(reponse.data.data);
      //  console.log(reponse.data.data);
      });
  }, []);
  useEffect(() => {
    axios
      .get(
        `https://mad-up.com/api/mad-up/items/metier?fields=id,image.*,file_zip.*,translations.*&lang=${lang}`
      )
      .then((reponse) => {
      //  console.log(reponse);
        setMetier(reponse.data.data);
        console.log(reponse.data.data);
      });
  }, []);
  const [auth, setAuth] = useState([]);
  useEffect(() => {
    const authUser = JSON.parse(sessionStorage.getItem("userData"));
    setAuth(authUser);
  }, []);

  if (auth === null) {
    return <Navigate to={`/${lang}/connexion`} />;
  }


  //connaitre la page courante manuelle
const indexOfLastArticle = currentPage * articlesPerPage;
const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
const currentArticles = manuel.slice(indexOfFirstArticle, indexOfLastArticle);
 // changement de page
 const paginate = pageNumber => setCurrentPage(pageNumber);


//connaitre la page courante version interface
const indexOfLastArticleInter = currentPageInter * articlesPerPageInter;
const indexOfFirstArticleInter = indexOfLastArticleInter - articlesPerPageInter;
const currentArticlesInter = inter.slice(indexOfFirstArticleInter, indexOfLastArticleInter);
 // changement de page
 const paginateInter = pageNumber => setCurrentPageInter(pageNumber);


//connaitre la page courante version metier
const indexOfLastArticleMet= currentPageMet * articlesPerPageMet;
const indexOfFirstArticleMet = indexOfLastArticleMet - articlesPerPageMet;
const currentArticlesMet = metier.slice(indexOfFirstArticleMet, indexOfLastArticleMet);
 // changement de page
 const paginateMet = pageNumber => setCurrentPageMet(pageNumber);
  return (
    <>
      <Meta meta={meta} />
      <HeaderAccount />
      <Breadcrumb title={data.breadcrumb} />
     
          <SideBar >
          <section className="technicalSupport">
            <div className="toolbox">
            <BigTitle
                text=""
                title=""
                span={data.bigtitle3.small}
                direct="left"
            />
            <div className='flexCard'>
          {(currentArticles)&&
              currentArticles.map((m) => {
                return ( 
        
			<Card key={m.id} 
                description={m.translations[0].description} 
                 title={m.translations[0].title} 
                full_url={m.file.filename_disk} 
                readMore={data.readMore} 
                readLess={data.readLess} 
                url_img={m.image.data.full_url}
      />
      
)
        })}  
  </div>
   
  <Pagination
                    articlesPerPage={articlesPerPage}
                    totalArticles={manuel.length}
                    paginate={paginate}
                    currentPage={currentPage}
                />
            </div>

            <div className="toolbox">
            <BigTitle
                text=""
                title=""
                span={data.bigtitle2.small}
                direct="left"
            />
            <div className='flexCard'>
{(currentArticlesInter)&&
              currentArticlesInter.map((m) => {
                return ( 
        
			<Card key={m.id} description={m.translations[0].description} title={m.translations[0].title} full_url={m.translations[0].file.filename_disk}  readMore={data.readMore} readLess={data.readLess} url_img={m.image.data.full_url}
      />
      
)
        })}  
  </div>
   
  <Pagination
                    articlesPerPage={articlesPerPageInter}
                    totalArticles={inter.length}
                    paginate={paginateInter}
                    currentPage={currentPageInter}
                />
            </div>



            <div className="toolbox">
            <BigTitle
                text=""
                title=""
                span={data.bigtitle.small}
                direct="left"
            />

<div className='flexCard'>
{(currentArticlesMet)&&
              currentArticlesMet.map((m) => {
                return ( 
        
			<Card key={m.id} description={m.translations[0].description} title={m.translations[0].title} full_url={m.file_zip.filename_disk}  readMore={data.readMore} readLess={data.readLess} url_img={m.image.data.full_url}
      />
      
)
        })}  
  </div>
   
  <Pagination
                    articlesPerPage={articlesPerPageMet}
                    totalArticles={metier.length}
                    paginate={paginateMet}
                    currentPage={currentPageMet}
                />
            </div>

            
          </section>
        </SideBar>
     
      <Footer />
    </>
  );
};

export default TechnicalSupport;
