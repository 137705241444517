import React,{useState, useEffect} from 'react';
import { withRouter,Redirect, useParams, Link} from "react-router-dom";
import './AddNewDevice.scss';
import axios from 'axios';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as dataJSON from "../../Data/Devices.json";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon
} from 'mdb-react-ui-kit'

export const AddDevice = ({date, numSerial, text1,title , ButtonText1, errorSerialNumber,numCount}) => {
 
    const { lang } = useParams();
    const [status, setStatus] = useState(false);
    const [basicModal, setBasicModal] = useState(false);
    const [data, setData] = useState(dataJSON.default[lang]);
  
    const toggleShow = () => {
      setBasicModal(!basicModal);
    };

    let auth=[];
    auth=JSON.parse(sessionStorage.getItem('userData'))

    const validationSchema = Yup.object().shape({
      serial_num: Yup.string().matches(/^[A-Z]{2}[0-9]{8}/,errorSerialNumber)
                          .length(10, numCount)
                          .required(text1)
      });
   
      const initialValues = {  
        serial_num: ""
      };

     const hostName="/Back-end/addNewDevice";
      const handleSubmit = (dataDevice) => {
           axios.post(hostName,dataDevice)
          .then((response)=>{
            console.log(response.data);
            if(response.data.status === false)
            {
              document.getElementById("errorSN").value = response.data.msg;
            }
            else
            {
              document.getElementById("errorSN").value = "";
              window.location.reload();
            }
          }).catch(error=>{
            console.log(error)
          })
      };
      
      return (
        <>
            <MDBIcon onClick={toggleShow} fas icon="plus-circle"  style={{ color: '#15acf2', fontSize: '3rem', margin:"5px" }}/>
            <div className='modalAdd'>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
        <MDBModalDialog  style={{margin:"200px auto auto",maxWidth:1000}}>
          <MDBModalContent style={{borderRadius:"25px", border:"1px solid #15acf2",paddingLeft:"10px", fontWeight:"bold"}}>
            <MDBModalHeader style={{border:"none", paddingBottom:"0px"}}>
              <MDBModalTitle style={{textTransform:"uppercase" , fontWeight:"bold"}}>{title}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>  <p className='error'></p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(deviceData)=>{
                  deviceData.serial_num = document.getElementById("serial_num").value;

                  console.log("submit ADD NEW DEVICE" );                  
                  handleSubmit(deviceData);}}
              >
                {({ 
                  handleChange,
                  handleBlur,
                  values,
                  setFieldValue,
                  handleReset
                   }) => (
                  <Form className='formDevice'>
                    <div className="form-group mb-4">
                        <label htmlFor="serial_num" className='me-2'>{numSerial}</label>
                          <Field
                            type="text"
                            id="serial_num"
                            name="serial_num"
                            placeholder="_ _ _ _ _ _ _ _ _"
                            visibility="hidden"
                          />
                        <ErrorMessage
                            name="serial_num"
                            component="small"
                            className="text-danger"
                          />

                        <label name="errorSN" id="errorSN" className='text-danger'></label>  
                    </div>
                    <MDBModalFooter className="d-flex justify-content-between align-items-start" style={{border:"none", paddingTop:"0px"}}>
             
             <button type="submit" style={{background:"#15acf2", border:"none", width:"150px", color:"#fff", padding:"10px", borderRadius:"10px", fontWeight:"bold"}}>{ButtonText1}</button>
                    
            
            </MDBModalFooter>
            <p className='msg'></p>
                  </Form>
                )}
              </Formik>
              </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      </div>      
          </>
      )
    };

export default AddDevice;
