import React , { useState, useEffect }from 'react'
import { useParams} from "react-router-dom";

import Meta from "../../Components/Meta";
import * as emailRecup from "../../Data/recupMail.json";
import Breadcrumb from '../../Components/Breadcrumb';
import RecupMail from '../../Components/SendMailResetPassword/MailRecup.jsx';
import Language from "../../Components/Languages"
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand
  } from 'mdb-react-ui-kit';
const MailReset= (props) => {
  const {lang} = useParams();
  const [data, setData] = useState(emailRecup.default[lang]);

  const [meta, setMeta] = useState({
      title: emailRecup.default[lang].meta.title,
      description: emailRecup.default[lang].meta.description,
      canonical: emailRecup.default[lang].meta.canonical,
      image: emailRecup.default[lang].meta.image,
      meta: {
          charset: "utf-8",
          name: {
              keywords: emailRecup.default[lang].meta.keywords,
          }

      },
  });

  useEffect(() => {
      setData(emailRecup.default[lang]);
      setMeta({
          title: emailRecup.default[lang].meta.title,
          description: emailRecup.default[lang].meta.description,
          canonical: emailRecup.default[lang].meta.canonical,
          keywords: emailRecup.default[lang].meta.keywords,
          image: emailRecup.default[lang].meta.image
      });
  }, [lang]);
  return (
    <>
    <Meta meta={meta} />
    <header >
      <MDBNavbar light bgColor='white' expand='lg' className='me-2 '>
          <MDBContainer>
            <MDBNavbarBrand href='#'>
              <img
                src="https://mad-up.com/api/uploads/mad-up/originals/traLogoMADAcademy.png"
                height='50'
                alt='logo MAD-UP ACADEMY'
                loading='lazy'
              />
            </MDBNavbarBrand>
            <div className='d-flex w-auto mb-3'> <Language/></div>
          </MDBContainer>
        </MDBNavbar>
    </header>
    <Breadcrumb title={data.breadcrumb} />
    <RecupMail email={data.form.LabelEmail} text={data.form.ButtonText1} 
    SLUGPassword={data.form.SLUGPassword} 
    TexTReturn={data.form.TexTReturn} 
    SLUGReturn={`/${lang}/${data.form.SLUGReturn}`} 
     TextSpan={data.form.TextSpan}
     TextSLUG={data.form.TextSLUG}
     SLUG={`/${lang}/${data.form.SLUG}`}
     message={data.form.message}
     messageError={data.form.messageError}
     />

    </>
  )
}

export default MailReset;