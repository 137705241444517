import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';

export default function FAQCard({created_on, question, answer, date, questionp, answerp}) {
    
    const convertDate=(inputFormat)=> {
        function pad(s) {
            return s < 10 ? "0" + s : s;
        }
        var d = new Date(inputFormat);
        return [
            pad(d.getDate()),
            pad(d.getMonth() + 1),
            d.getFullYear(),
        ].join("/");
    }
  return (
    <Card sx={{ minWidth: 275 }} style={{border:"1px solid #000", borderRadius:"20px", margin:"auto"}}>
         <CardHeader
        avatar={
          <Avatar alt="Mad-up" src="https://mad-up.com/api/uploads/mad-up/originals/avatarImg.png" 
          sx={{ width: 50, height: 50 }}
          />
        }
        subheader= {
            <span> {date}  &nbsp;
          { convertDate(created_on)}
            </span>
        }
      />
      <CardContent>
        <Typography sx={{ mb: 1.5 }}>
         {question}:  {questionp}
        </Typography>
        <Typography sx={{ mb: 1.5 }}>
         {answer}:  {answerp}
        </Typography>
      </CardContent>
    
    </Card>
  );
}